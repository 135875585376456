import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// styles
import "./Question.css";

// material ui
import Button from "@mui/material/Button";

// utils
import Webservice from "../../utils/Webservice";

// components
import QuestionComment from "../../components/QuestionComment/QuestionComment";


export default function Question() {
  // params
  let { questionId } = useParams();

  // state
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [answers, setAnswers] = useState([]);

  // effects
  useEffect(() => {
    const fetchData = async () => {
      Webservice.post("questions", {
        route: "getPost",
        questionId,
      }).then((result) => {
        console.log(result);
        setQuestion(result.result);
        setAnswers(result.replies);
      });
    };

    fetchData();
  }, [questionId]);

  const handleAddComment = () => {
    Webservice.post("questions", {
      route: "addComment",
      questionId,
      answer,
    }).then((result) => {
      console.log(result);
      setAnswers([result.result, ...answers]);
      setAnswer("");
    });
  };

  const handleInputChange = (event) => {
    setAnswer(event.target.value);
  };

  const formatTimeDifference = (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();

    const timeDifferenceInMilliseconds = currentDate - creationDate;
    const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
    const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
    const timeDifferenceInHours = timeDifferenceInMinutes / 60;
    const timeDifferenceInDays = timeDifferenceInHours / 24;
    const timeDifferenceInMonths = timeDifferenceInDays / 30;

    if (timeDifferenceInSeconds < 60) {
      return `${Math.round(timeDifferenceInSeconds)} seconds ago`;
    } else if (timeDifferenceInMinutes < 60) {
      return `${Math.round(timeDifferenceInMinutes)} minutes ago`;
    } else if (timeDifferenceInHours < 24) {
      return `${Math.round(timeDifferenceInHours)} hours ago`;
    } else if (timeDifferenceInDays < 30) {
      return `${Math.round(timeDifferenceInDays)} days ago`;
    } else if (timeDifferenceInMonths < 12) {
      return `${Math.round(timeDifferenceInMonths)} months ago`;
    } else {
      const day = creationDate.getDate();
      const month = creationDate.getMonth() + 1;
      const year = creationDate.getFullYear();

      return `${day}/${month}/${year}`;
    }
  };

  return (
    <div className="question-container">
      {question && (
        <div className="question-section">
          <div className="question-data">
            <p className="name-user">{question.userName.substring(0,2)}</p>
            <p className="time-question">{formatTimeDifference(question.timestamp)}</p>
          </div>
          <p className="question-title">{question.question}</p>
          <p className="question-description">{question.description}</p>
        </div>
      )}

      <div className="question-answer-list">
        {answers.map((answ) => (
          <div className="question-answer-detail" key={answ.rowKey}>
            <QuestionComment reply={answ} />
          </div>
        ))}
      </div>
      <div className="question-answer-box">
        <textarea
          value={answer}
          className="question-answer-text"
          onChange={handleInputChange}
          rows={5}
        ></textarea>
        <Button
          variant="contained"
          className="btn-primary"
          onClick={handleAddComment}
        >
          Add Comment
        </Button>
      </div>
    </div>
  );
}
