import React, { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";

// styles
import "./Forum.css";

// material ui
import { IconButton } from "@mui/material";
import { Divider } from "@mui/material";

// components
import Footer from "../../components/Footer/Footer";
import CutSearch from "../../components/CutSearch/CutSearch";
import Question from "../../components/Question/Question";
import QuestionReply from "../../components/QuestionReply/QuestionReply";

// icons
import { AiOutlineLike } from "react-icons/ai";
import { GoCommentDiscussion } from "react-icons/go";
import { IoArrowBackCircleOutline } from "react-icons/io5";

// images
import ImageUserReview from "../../assets/images/G1.jpg";
import SearchOff from "../../assets/images/SearchOffIcon.png";

function Forum() {
  const [loading, setLoading] = useState(true);

  const [questionsData, setQuestionsData] = useState(null);
  const [State, setState] = useState("all");
  const [Rate, setRate] = useState("asc");

  useEffect(() => {
    const fetchDataQuestions = async () => {
      try {
        const params = new URLSearchParams({
          state: State,
          sort: "desc",
          rate: Rate,
        });

        const response = await fetch(`/api/questions?${params.toString()}`);

        if (!response.ok) {
          throw new Error(`Request error: ${response.status}`);
        } else {
          console.log(response);
        }

        const data = await response.json();
        console.log(data);
        setQuestionsData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la información: ", error);
      }
    };

    fetchDataQuestions();
  }, [State, Rate]);

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [backQuestions, setbackQuestions] = useState(false);

  console.log(questionsData);

  const handleQuestionSelection = (question) => {
    setSelectedQuestion(question);
    setbackQuestions(true);
  };

  const handleBackToQuestions = () => {
    setbackQuestions(false);
  };

  const formatTimeDifference = (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();

    const timeDifferenceInMilliseconds = currentDate - creationDate;
    const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
    const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
    const timeDifferenceInHours = timeDifferenceInMinutes / 60;
    const timeDifferenceInDays = timeDifferenceInHours / 24;
    const timeDifferenceInMonths = timeDifferenceInDays / 30;

    if (timeDifferenceInSeconds < 60) {
      return `${Math.round(timeDifferenceInSeconds)} seconds ago`;
    } else if (timeDifferenceInMinutes < 60) {
      return `${Math.round(timeDifferenceInMinutes)} minutes ago`;
    } else if (timeDifferenceInHours < 24) {
      return `${Math.round(timeDifferenceInHours)} hours ago`;
    } else if (timeDifferenceInDays < 30) {
      return `${Math.round(timeDifferenceInDays)} days ago`;
    } else if (timeDifferenceInMonths < 12) {
      return `${Math.round(timeDifferenceInMonths)} months ago`;
    } else {
      const day = creationDate.getDate();
      const month = creationDate.getMonth() + 1;
      const year = creationDate.getFullYear();

      return `${day}/${month}/${year}`;
    }
  };

  if (loading) {
    // Mientras se está realizando el proceso de autenticación, muestra un indicador de carga
    return (
      <div className="LoadingPage">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={[
            "#379AE6FF",
            "#379AE6FF",
            "#379AE6FF",
            "#379AE6FF",
            "#379AE6FF",
          ]}
        />
      </div>
    );
  }

  return (
    <div className="forum">
      <CutSearch />
      <div className="forum-container">
        <div className="questions-forum">
          {backQuestions ? (
            <div className="selected-question">
              <IconButton
                className="back-btn-container"
                onClick={handleBackToQuestions}
              >
                <IoArrowBackCircleOutline className="back-btn" size={32} />
              </IconButton>

              <Question question={selectedQuestion} />
            </div>
          ) : (
            <>
              <div className="questions-btns">
                <p onClick={() => setState("all")}>All questions</p>
                <p onClick={() => setState("open")}>Open questions</p>
                <p onClick={() => setRate("desc")}>Most voted</p>
              </div>
              <div className="questions">
                {questionsData ? (
                  questionsData.map((question) => (
                    <div>
                      <QuestionReply
                        key={question.partitionKey}
                        question={question}
                      ></QuestionReply>

                      <div
                        className="user-questions-forum"
                        key={question.partitionKey}
                        onClick={() => handleQuestionSelection(question)}
                      >
                        <div className="image-user-question">
                          <img src={ImageUserReview} alt="UserQuestion" />
                          <div className="user-question-section">
                            <div className="user-question-data">
                              <p className="name-user-question">
                                {question.userName}
                              </p>
                              <p className="time-question">
                                {formatTimeDifference(question.createdAt)}
                              </p>
                            </div>
                            <p className="question-txt">{question.question}</p>
                          </div>
                        </div>

                        <div className="question-valoration">
                          <div className="like-question">
                            <AiOutlineLike
                              className="question-valoration-icon"
                              size={12}
                            />
                            <p>10</p>
                          </div>
                          <div className="comment-question">
                            <GoCommentDiscussion
                              className="question-valoration-icon"
                              size={12}
                            />
                            <p>20</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-found-questions">
                    <div>
                      <img
                        className="search-Off-icon"
                        src={SearchOff}
                        alt="Search no found."
                      />
                    </div>
                    <div className="no-found-txt">
                      <p>No related questions found.</p>
                      <p>Create a new one :)</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="topics-forum">
          <h5>POPULAR TOPICS</h5>
          <p>ABAP Development</p>
          <p>SAP Business One</p>
          <p>SAP Analytics Cloud</p>
          <p>SAP S/4HANA</p>
          <p>SAP S/4HANA Cloud for Finance</p>
          <p>MM (Materials Management)</p>
          <p>SAP Integration Suite</p>
          <Divider className="divider-topics" />
          <h5>RELATED</h5>
          <p>SAP Business One Extensibility</p>
          <p>SAP Business One, for SAP HANA</p>
          <p>SAP Crystal Reports</p>
          <p>SQL</p>
          <p>SAP ERP</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Forum;
