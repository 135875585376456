import React, { useState, useEffect } from "react";

// styles
import "./SAPModulesFormVersions.css";

// mui
import Checkbox from "@mui/material/Checkbox";

const items = {
  //
  versions: {
    1: ["SAP R/2", "SAP R/2"],
    2: ["SAP R/3 (up to 4.6)", "SAP R/3 (up to 4.6)"],
    3: ["SAP R/3 (Enterprise)", "SAP R/3 (Enterprise)"],
    4: ["SAP ECC (mySAP ERP 2004, 5.0)", "SAP ECC (mySAP ERP 2004, 5.0)"],
    5: ["SAP ECC (SAP ERP 6.0)", "SAP ECC (SAP ERP 6.0)"],
    6: ["SAP S/4HANA On-Premise", "SAP S/4HANA On-Premise"],
    7: ["SAP S/4HANA Cloud Private Edition", "SAP S/4HANA Cloud Private Edition"],
    8: ["SAP S/4HANA Cloud Public Edition", "SAP S/4HANA Cloud Public Edition"],
  },
  versions_category: "SAP Versions",

};

let categories = Object.keys(items).filter((f) => !f.includes("_"));

export default function SAPModulesFormVersions({ onModulesSelected, selectedModules }) {
  // Estado para almacenar las teclas seleccionadas
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    if (!isCheck && selectedModules !== selectedKeys) {
      setSelectedKeys(selectedModules);
      setIsCheck(false);
    }
  }, [selectedModules, isCheck, selectedKeys]);

  useEffect(() => {
    if (isCheck) {
      onModulesSelected(selectedKeys);
    }
  }, [selectedKeys, isCheck, onModulesSelected]);

  // Controlador de eventos para actualizar las teclas seleccionadas
  const handleCheckboxChange = (key) => {
    // Verifica si la tecla ya está seleccionada y actualiza el estado en consecuencia
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter((item) => item !== key));
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
    setIsCheck(true);
  };

  return (
    <div className="sap-modules-container">
      <div className="sap-modules-categories">
        {categories.map((category, key) => (
          <div key={category + key} className="modules-category">
            <p className="title-module">{items[category + "_category"]}</p>
            {Object.values(items[category]).map((value) => (
              <div key={category + key} className="modules-check">
                <Checkbox
                  size="small"
                  name={value[0]}
                  id={value[0]}
                  checked={selectedKeys.includes(value[0])}
                  onChange={() => handleCheckboxChange(value[0])}
                />
                <label htmlFor={key}>
                  {value[1]}
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
