import React, { useEffect, useRef, useState } from "react";
// import "./LoginRegistration.css";
//import { BsFacebook } from "react-icons/bs";

// material ui
import { Button } from "@mui/material";

// icons
import { AiOutlineGoogle } from "react-icons/ai";
import { SiMicrosoftoutlook } from "react-icons/si";
import { AiOutlineApple } from "react-icons/ai";

// images
import CogniSapLogo from "../../assets/images/cognisap-logo.png";

//components
import Registration from "./Registration";

export default function Login({ onLogin, onClose }) {
  const loginPopupRef = useRef(null);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const RegisterPopupRef = useRef(null);

  // Refs para el componente Login y el formulario de Registro
  // const handleRegister = () => {
  //   setShowRegisterPopup(true);
  // };

  // Función para manejar el clic en el botón de inicio de sesión
  const handleLoginGoogleClick = () => {
    // Realiza el inicio de sesión o cualquier otra lógica necesaria.

    window.location.replace(
      "/.auth/login/google?post_login_redirect_uri=/api/signup"
    );
    onLogin();

    // Cierra el popup llamando a la función onClose
    onClose();
  };

  const handleLoginMicrosoftClick = () => {
    // Realiza el inicio de sesión o cualquier otra lógica necesaria.

    window.location.replace(
      "/.auth/login/aad?post_login_redirect_uri=/api/signup"
    );
    onLogin();

    // Cierra el popup llamando a la función onClose
    onClose();
  };

  // Efecto para cerrar el formulario de inicio de sesión cuando se hace clic fuera de él
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        loginPopupRef.current &&
        !loginPopupRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    // Agrega un event listener para detectar clics en el documento
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      // Limpia el event listener cuando el componente se desmonta
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div className="login-container" ref={loginPopupRef}>
      <div className="login-left-side">
        <h1>Login</h1>
        <h6>Login to use the SAP Assistant</h6>
        <div>
          <div className="text-left-side">
            <p>Select your identity provider</p>
          </div>

          <div className="providers-btns">
            <Button
              onClick={handleLoginGoogleClick}
              variant="contained"
              startIcon={<AiOutlineGoogle />}
              className="google-btn"
            >
              Google
            </Button>
            <Button
              onClick={handleLoginMicrosoftClick}
              variant="contained"
              startIcon={<SiMicrosoftoutlook />}
              className="microsoft-btn"
            >
              Microsoft
            </Button>

            <Button
              variant="contained"
              startIcon={<AiOutlineApple />}
              className="apple-btn"
            >
              Apple
            </Button>
          </div>
        </div>
      </div>
      <div className="login-right-side">
        <img
          className="cognisap-logo-login"
          src={CogniSapLogo}
          alt="CogniSap Logo"
        />

        <p>
          Experience the power of SAP with our <br></br>{" "}
          <b>AI-enabled SAP Assistant</b>
          <br></br>
          available at no cost. Enjoy unlimited inquiries and receive instant
          responses to all your SAP-related questions.
        </p>
        <p>
          Connect with top-tier SAP consultants and immerse yourself in the
          dynamic world of SAP projects.
        </p>
        <p>
          Join our premier SAP community and enhance your professional journey
          with unparalleled expertise and insights
        </p>
      </div>
      {/* Muestra el formulario de registro si showRegisterPopup es true */}
      {showRegisterPopup && (
        <div className="overlay" ref={RegisterPopupRef}>
          <Registration
            onClose={() => setShowRegisterPopup(false)}
            onLogin={onLogin}
          />
        </div>
      )}
    </div>
  );
}
