import React, { useState, useEffect } from "react";

// styles
import "./SAPModulesFormAreas.css";

// mui
import Checkbox from "@mui/material/Checkbox";

const items = {
  //1. ERP (Core)
  erp: {
    1: ["1.1 Finanzas o Contabilidad"],
    2: ["1.2 Gestión de Costos o Contabilidad de Costos"],
    3: ["1.3 Ventas y Distribución"],
    4: ["1.4 Gestión de Materiales"],
    5: ["1.5 Planificación de la ProducciónERP"],
    6: ["1.6 Gestión de Calidad"],
    7: ["1.7 Mantenimiento de Planta"],
    8: ["1.8 Servicio al Cliente"],
    9: ["1.9 Gestión de Proyectos"],
    10: ["1.10 Gestión de Recursos Humanos"],
  },
  erp_category: "1. ERP (Core)",

  //2. Gestion Financiera
  financiera: {
    1: ["2.1 Planificación y análisis financiero (FP&A)"],
    2: ["2.2 Cierre contable y financiero"],
    3: ["2.3 Gestión de impuestos"],
    4: ["2.4 Gestión de tesorería"],
    5: ["2.5 Gestión de Quote-to-Cash"],
    6: ["2.6 Gobernanza, riesgo y cumplimiento (GRC) y ciberseguridad"],
  },
  financiera_category: "2. Gestion Financiera",

  //3. CRM y experiencia de cliente
  crm: {
    1: ["3.1 E-commerce"],
    2: ["3.2 Ventas y Servicios"],
    3: ["3.3 Marketing"],
    4: ["3.4 Datos del Cliente"],
  },
  crm_category: "3. CRM y experiencia de cliente",

  //4. Gestión del gasto
  gasto: {
    1: ["4.1 Gastos directos e indirectos (SAP Ariba)"],
    2: ["4.2 Gestión de fuerza laboral externa (SAP Fieldglass)"],
    3: ["4.3 Gastos de Viajes (SAP Concur)"],
  },
  gasto_category: "4. Gestión del gasto",

  //5. Tecnología
  tecnologia: {
    1: ["9.1 Automatización y desarrollo de aplicaciones"],
    2: ["9.2 Planificación y análisis extendidos"],
    3: ["9.3 Datos y analíticas"],
    4: ["9.4 Integración"],
    5: ["9.5 Innovación e Inteligencia artificial"],
  },
  tecnologia_category: "5. Tecnología",

  //6. Others
  others: {
    1: ["Gestión de la cadena de Suministro (SAP SCM)"],
    2: ["Gestión de sostenibilidad"],
    3: ["Gestión de Capital Humano (SAP SuccessFactors HXM)"],
    4: ["Transformación de Procesos de negocio (SAP Signavio)"],
  },
  others_category: "6. Others",

};

let categories = Object.keys(items).filter((f) => !f.includes("_"));

export default function SAPModulesFormAreas({
  onModulesSelected,
  selectedModules,
}) {
  // Estado para almacenar las teclas seleccionadas
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    if (!isCheck && selectedModules !== selectedKeys) {
      setSelectedKeys(selectedModules);
      setIsCheck(false);
    }
  }, [selectedModules, isCheck, selectedKeys]);

  useEffect(() => {
    if (isCheck) {
      onModulesSelected(selectedKeys);
    }
  }, [selectedKeys, isCheck, onModulesSelected]);

  // Controlador de eventos para actualizar las teclas seleccionadas
  const handleCheckboxChange = (key) => {
    // Verifica si la tecla ya está seleccionada y actualiza el estado en consecuencia
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter((item) => item !== key));
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
    setIsCheck(true);
  };

  return (
    <div className="sap-modules-container">
      <div className="sap-modules-chips">
        {selectedKeys.map((key) => (
          <div key={key} className="sap-modules-chip">
            {key}
          </div>
        ))}
      </div>

      <div className="sap-modules-categories">
        {categories.map((category, key) => (
          <div key={category + key} className="modules-category">
            <p className="title-module">{items[category + "_category"]}</p>
            {Object.values(items[category]).map((value) => (
              <div key={category + key} className="modules-check">
                <Checkbox
                  size="small"
                  name={value[0]}
                  id={value[0]}
                  checked={selectedKeys.includes(value[0])}
                  onChange={() => handleCheckboxChange(value[0])}
                />
                <label htmlFor={key}>{value[0]}</label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
