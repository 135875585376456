import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { ThreeDots } from "react-loader-spinner";

import Markdown from "react-markdown";

import "./ChatAssistant.css";

// context
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "../../context/QueryContext";

// icons
import { IoMdSearch } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";
import { BsChatSquare } from "react-icons/bs";
import { FaRegCircleStop } from "react-icons/fa6";

// material ui
import { Button } from "@mui/material";

// components
import Footer from "../../components/Footer/Footer";
import ModalFormCompany from "../../components/ModalFormCompany/ModalFormCompany";
import ModalFormConsultant from "../../components/ModalFormConsultant/ModalFormConsultant";
import ModalUserType from "../../components/ModalUserType/ModalUserType";
import SearchMessage from "../../components/SearchMessage/SearchMessage";
import MarkdownTypewriter from "../../components/MarkdownTypewriter/MarkdownTypewriter";
import ChatHistory from "../../components/ChatHistory/ChatHistory";

// utils
import Webservice from "../../utils/Webservice";

export default function ChatAssistant() {
  const { query, setQuery } = useQuery();

  // state
  const [threadId, setThreadId] = useState(null);
  const [message, setMessage] = useState("");
  const [searchCount, setSearchCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadChat, setIsLoadChat] = useState(false);
  const [isModalFormCompanyOpen, setIsModalFormCompanyOpen] = useState(false);
  const [isModalFormConsultantOpen, setIsModalFormConsultantOpen] =
    useState(false);
  const [messages, setMessages] = useState([]);
  const [topics, setTopics] = useState([]);

  // refs
  const textAreaRef = useRef(null);
  const chatContainerRef = useRef(null);
  const bottomRef = useRef(null);

  // context
  const { isAuthenticated, userData } = useContext(AuthContext);

  const search = useCallback(
    async (text) => {
      let count = 0 + searchCount;
      setSearchCount(++count);

      setLoading(true);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "user",
          text: text,
        },
      ]);

      if (userData && !userData.profile && messages.length > 2) {
        if (userData.rol === "COMPANY") {
          setIsModalFormCompanyOpen(true);
        }

        if (userData.rol === "CONSULTANT") {
          setIsModalFormConsultantOpen(true);
        }
      }

      Webservice.post("ChatAssistant", {
        route: "search",
        message: text,
        userType: userData.rol,
        threadId: threadId,
      }).then((result) => {
        setThreadId(result.threadId);

        const response = result.response;

        setTopics(
          result.topics.suggested_topics ? result.topics.suggested_topics : []
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "assistant",
            text: response,
          },
        ]);
        setLoading(false);
      });
      setIsLoadChat(false);
      setMessage("");
    },
    [searchCount, userData, messages.length, threadId]
  );

  useEffect(() => {
    if (isAuthenticated && userData) {
      setIsModalOpen(userData && !userData.rol);
    }
  }, [userData, isAuthenticated]);

  useEffect(() => {
    const fetchAndSendMessage = async () => {
      try {
        if (query && query !== "") {
          localStorage.removeItem("searchQuery");
          setQuery("");
          setMessage(query);
          search(query);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (query && userData) {
      fetchAndSendMessage();
    }
  }, [query, userData, setQuery, search]);

  const performSearchSuggestions = (topic) => {
    setMessage(topic.topic);
  };

  const performSearch = async () => {
    if (!userData || !message || message === "") {
      return;
    }

    search(message);
  };

  const handleNewMessage = (newMessage) => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleNewChat = () => {
    setMessages([
      {
        type: "assistant",
        text: `
        Welcome to our SAP expert consulting chat! 
        Here to help with your SAP questions or needs. 
        
        How may I support you today?

        ¡Bienvenido a nuestro chat de consultoría experta en SAP! 
        Aquí para ayudarte con tus dudas o necesidades de SAP. 
        
        ¿Cómo puedo apoyarte hoy?
        `,
      },
    ]);
    setThreadId(null);
    setTopics([]);
    setLoading(false);
  };

  const enterPressed = async (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault(); // Evita el comportamiento por defecto (salto de línea)
      await performSearch();
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const handleOpenChat = (messages, threadId) => {
    setMessages(messages);
    setThreadId(threadId);
    setTopics([]);
    setLoading(false);
    setIsLoadChat(true);
    setTimeout(() => {
      handleNewMessage();
    }, 1000);
  };

  return (
    isAuthenticated && (
      <div className="chat">
        {isModalFormCompanyOpen && (
          <ModalFormCompany
            isModalOpen={isModalFormCompanyOpen}
            setIsModalOpen={setIsModalFormCompanyOpen}
          />
        )}
        {isModalFormConsultantOpen && (
          <ModalFormConsultant
            isModalOpen={isModalFormConsultantOpen}
            setIsModalOpen={setIsModalFormConsultantOpen}
          />
        )}
        {isModalOpen && <ModalUserType />}

        <div className="chat-gpt-container">
          <div className="chat-history-container">
            <ChatHistory
              isDoingSearch={loading}
              onNewChat={handleNewChat}
              onLoadChat={handleOpenChat}
            />
          </div>
          <div className="chat-main-container">
            <div className="chat-container">
              <div className="chat-inside-container">
                <div className="chat-text">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={
                        msg.type === "assistant"
                          ? "incoming-message"
                          : "outgoing-message"
                      }
                    >
                      <div
                        className={`message ${
                          msg.type === "assistant"
                            ? "received-message"
                            : "sent-message"
                        }`}
                      >
                        {msg.type === "assistant" &&
                        !isLoadChat &&
                        index === messages.length - 1 ? (
                          <MarkdownTypewriter
                            text={msg.text}
                            onTextChange={handleNewMessage}
                          />
                        ) : (
                          <Markdown>{msg.text}</Markdown>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <SearchMessage
                onMessageChange={handleNewMessage}
                loading={loading}
              />

              {loading && (
                <div className="waiting-animation">
                  <ThreeDots
                    visible={true}
                    height="30"
                    width="30"
                    color="#9095A0FF"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              )}
              <div ref={bottomRef} className="chat-divider"></div>
            </div>
            <div className="ask-expert">
              <p>Not satisfied?</p>
              <Button
                className="expert-btn btn-secondary"
                variant="contained"
                onClick={() => {
                  window.location.href = "/consultants";
                }}
                startIcon={<AiOutlineUser />}
                disabled={loading}
              >
                Ask to an expert
              </Button>
            </div>
            <div className="input-chat-suggestions">
              <div className="input-chat" ref={chatContainerRef}>
                <BsChatSquare size={20} />
                <textarea
                  ref={textAreaRef}
                  className="bar-chat"
                  placeholder="Add more context to your questions..."
                  value={message}
                  onChange={handleInput}
                  disabled={loading}
                  onKeyUp={enterPressed}
                  autoComplete="false"
                  rows={1}
                />

                {loading ? (
                  <Button className="stop-btn btn-search">
                    <FaRegCircleStop size={20} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="search-btn btn-search"
                    onClick={performSearch}
                  >
                    <IoMdSearch />
                  </Button>
                )}
              </div>
              <div className="suggestions">
                <p>Suggestions</p>

                {topics.map((topic, key) => (
                  <Button
                    className="suggestions-btn"
                    size="small"
                    variant="outlined"
                    key={key}
                    onClick={() => performSearchSuggestions(topic)}
                  >
                    {topic.topic}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    )
  );
}
