import React, { useState, useContext, useEffect } from "react";

// styles
import "./ModalFormConsultant.css";

// context
import { AuthContext } from "../../context/AuthContext";

// utils
import Webservice from "../../utils/Webservice";

// components
// import SAPModulesForm from "../SAPModulesForm/SAPModulesForm";

// material ui
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

export default function ModalFormConsultant({isModalOpen, setIsModalOpen}) {
  // context
  const { reloadUser, isAuthenticated, userData } = useContext(AuthContext);

  // states
  const [step, setStep] = useState(1);
  const [shouldClose, setShouldClose] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    years: "",
    modules: [],
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    email: "",
    years: "",
  });

  // effects
  useEffect(() => {
    if (setShouldClose) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        modules: selectedModules,
      }));
    }
  }, [setShouldClose, selectedModules, setFormData]);

  useEffect(() => {
    if (isAuthenticated && userData.profile) {

      if( typeof userData.profile.modules === 'string') {
        userData.profile.modules = userData.profile.modules.split(',')
      }

      setSelectedModules(userData.profile.modules);

      setFormData((prevFormData) => ({
        ...prevFormData,
        ...userData.profile,
      }));
    }
  }, [isAuthenticated, userData, setFormData]);

  // methods
  // const handleModulesSelected = (modules) => {
  //   setSelectedModules(modules);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Required field";
    }

    if (!formData.phone) {
      errors.phone = "Required field";
    }

    if (!formData.email) {
      errors.email = "Required field";
    }

    if (!formData.years) {
      errors.years = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      setStep(2);
      console.log(formData);
      setErrorMessages({});
    } else {
      setErrorMessages(errors);
    }
  };

  const handleBack = () => {
    setStep(1);
    console.log(formData);
  };

  const handleSave = () => {
    formData.modules = formData.modules.join(",");
    console.log(formData);
    const saveData = async () => {
      await Webservice.post("consultants", {
        route: "setProfile",
        ...formData,
      });

      reloadUser();
    };

    saveData();

    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
      open={isModalOpen}
        onClose={shouldClose ? handleContinue : () => {}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box className="consultant-form-container">
            <div className="modal-consultant-form">
              <div className="img-form-consultant"></div>

              <div className="consultants-form">
                <p className="title-consultant-modal">Tell us about yourself</p>
                {step === 1 && (
                  <div className="consultant-modal-content">
                    <div className="consultant-form-txt">
                      <p>
                        Before continuing with the chat we would like, to know
                        more about you:
                      </p>
                    </div>

                    <form className="consultant-form-modal">
                      <div className="consultant-form-field">
                        <label className="title-consultant-inputs">
                          Name
                          <input
                            type="text"
                            name="name"
                            className="inputs-consultant-form"
                            placeholder="James Harrid"
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </label>
                        {errorMessages.name && (
                          <span className="consultant-required-message">
                            {errorMessages.name}
                          </span>
                        )}
                      </div>

                      <div className="consultant-form-field">
                        <label className="title-consultant-inputs">
                          Phone
                          <input
                            type="number"
                            name="phone"
                            className="inputs-consultant-form"
                            placeholder="123-456-7890"
                            value={formData.phone}
                            onChange={handleInputChange}
                          />
                        </label>
                        {errorMessages.phone && (
                          <span className="consultant-required-message">
                            {errorMessages.phone}
                          </span>
                        )}
                      </div>

                      <div className="consultant-form-field">
                        <label className="title-consultant-inputs">
                          Email
                          <input
                            type="email"
                            name="email"
                            className="inputs-consultant-form"
                            placeholder="example@email.com"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </label>
                        {errorMessages.email && (
                          <span className="consultant-required-message">
                            {errorMessages.email}
                          </span>
                        )}
                      </div>

                      <div className="consultant-form-field">
                        <label className="title-consultant-inputs">
                          Years of professional experience
                          <select
                            name="years"
                            className="selects-consultant-form"
                            value={formData.years}
                            onChange={handleInputChange}
                          >
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="+10">+10</option>
                          </select>
                        </label>
                        {errorMessages.years && (
                          <span className="consultant-required-message">
                            {errorMessages.years}
                          </span>
                        )}
                      </div>

                      <div className="consultant-container-btn">
                        <Button
                          className="btn-primary"
                          onClick={handleContinue}
                        >
                          Continue
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
                {step === 2 && (
                  <div className="consultant-modal-content">
                    {/* <div className="consultant-form-txt">
                      <p>Which SAP modules are you experienced in?</p>
                    </div>
                    <form className="consultant-continue-form">
                      <SAPModulesForm
                        onModulesSelected={handleModulesSelected}
                        selectedModules={selectedModules}
                      /> */}
                      <div className="consultant-container-btn">
                        <Button className="btn-secondary" onClick={handleBack}>
                          Back
                        </Button>
                        <Button className="btn-primary" onClick={handleSave}>
                          Save changes
                        </Button>
                      </div>
                    {/* </form> */}
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
