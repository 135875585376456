import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";

// styles
import "./AdminCompanies.css";

// utils
import Webservice from "../../utils/Webservice";

// components
import Footer from "../../components/Footer/Footer";
import TableData from "../../components/TableData/TableData";

const ACTION_APPROVE = "Approve";
const ACTION_REJECT = "Reject";
const ACTION_PENDING = "Pending Approval";

const actions = [
  {
    text: ACTION_APPROVE,
    icon: "approve",
  },
  {
    text: ACTION_REJECT,
    icon: "reject",
  },
  {
    text: ACTION_PENDING,
    icon: "pending",
  },
];
const headers = [
  {
    id: "id",
    numeric: false,
    label: "Id",
  },
  {
    id: "name",
    numeric: false,
    label: "Company name",
  },
  {
    id: "domain",
    numeric: false,
    label: "Domain",
  },
  {
    id: "status",
    numeric: false,
    label: "Company Status",
  },
  {
    id: "nif",
    numeric: false,
    label: "NIF",
  },
  {
    id: "username",
    numeric: false,
    label: "Registered by",
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
  },
  {
    id: "phone",
    numeric: false,
    label: "Phone",
  },
];

export default function AdminCompanies() {
  // states
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDataConsultants = async () => {
      Webservice.post("company", {
        route: "getAll",
      }).then((result) => {
        result.companies = result.companies.map((d, i) => ({
          id: i + 1,
          ...d,
        }));
        console.log(result);
        setCompanyData(result.companies);
      });
    };

    fetchDataConsultants();
  }, []);

  const handleAction = (action, rows) => {
    console.log(action, rows);
    setLoading(true);
    if (action.text === ACTION_APPROVE) {
      Webservice.post("company", {
        route: "approve",
        rows,
      }).then((result) => {
        window.location.reload();
      });
    }

    if (action.text === ACTION_REJECT) {
      Webservice.post("company", {
        route: "reject",
        rows,
      }).then((result) => {
        window.location.reload();
      });
    }

    if (action.text === ACTION_PENDING) {
      Webservice.post("company", {
        route: "pending",
        rows,
      }).then((result) => {
        window.location.reload();
      });
    }
  };

  return (
    <div className="companies">

      <div className="companies-container">
        {!loading && companyData ? (
          <TableData
            title={"Companies"}
            headers={headers}
            rows={companyData}
            onClickAction={handleAction}
            actions={actions}
          />
        ) : (
          <div className="loading-page">
            <ColorRing
              visible={true}
              height="40"
              width="40"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={[
                "#379AE6FF",
                "#379AE6FF",
                "#379AE6FF",
                "#379AE6FF",
                "#379AE6FF",
              ]}
            />
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}
