import React, { useState, useEffect, useContext } from "react";

// styles
import "./ModalFormCompany.css";

// context
import { AuthContext } from "../../context/AuthContext";

// material ui
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

// utils
import Webservice from "../../utils/Webservice";

// components
// import SAPModulesForm from "../SAPModulesForm/SAPModulesForm";

const sapIndustries = [
  "Automotive",
  "Chemicals",
  "Construction and Real Estate",
  "Consumer Products",
  "Financial Services",
  "High Tech and Electronics",
  "Manufacturing",
  "Oil and Gas",
  "Pharmaceuticals and Healthcare",
  "Public Sector",
  "Retail",
  "Telecommunications",
  "Transportation and Logistics",
  "Utilities",
];

export default function ModalFormCompany({ isModalOpen, setIsModalOpen }) {
  // context
  const { reloadUser, isAuthenticated, userData } = useContext(AuthContext);

  // states
  const [step, setStep] = useState(1);
  const [shouldClose, setShouldClose] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    sector: "",
    modules: [],
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    email: "",
    sector: "",
  });

  // effects
  useEffect(() => {
    if (setShouldClose) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        modules: selectedModules,
      }));
    }
  }, [setShouldClose, selectedModules, setFormData]);

  useEffect(() => {
    if (isAuthenticated && userData.profile) {
      if (typeof userData.profile.modules === "string") {
        userData.profile.modules = userData.profile.modules.split(",");
      }

      setSelectedModules(userData.profile.modules);

      setFormData((prevFormData) => ({
        ...prevFormData,
        ...userData.profile,
      }));
    }
  }, [isAuthenticated, userData, setFormData]);

  // methods
  // const handleModulesSelected = (modules) => {
  //   setSelectedModules(modules);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Required field";
    }

    if (!formData.phone) {
      errors.phone = "Required field";
    }

    if (!formData.email) {
      errors.email = "Required field";
    }

    if (!formData.sector) {
      errors.sector = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      setStep(2);
      console.log(formData);
      setErrorMessages({});
    } else {
      setErrorMessages(errors);
    }
  };

  const handleBack = () => {
    setStep(1);
    console.log(formData);
  };

  const handleSave = () => {
    formData.modules = formData.modules.join(",");
    console.log(formData);
    const saveData = async () => {
      await Webservice.post("company", {
        route: "setProfile",
        ...formData,
      });

      reloadUser();
    };

    saveData();

    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={shouldClose ? handleContinue : () => {}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box className="company-form-container">
            <div className="modal-company-form">
              <div className="companies-form">
                <p className="title-company-modal">Tell us about yourself</p>
                {step === 1 && (
                  <div className="company-modal-content">
                    <div className="company-form-txt">
                      <p>
                        Before continuing with the chat we would like, to know
                        more about you:
                      </p>
                    </div>

                    <form className="company-form-modal">
                      <div className="company-form-field">
                        <label className="title-company-inputs">
                          Company Name
                          <input
                            type="text"
                            name="name"
                            className="inputs-company-form"
                            placeholder="James Harrid"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.name && (
                          <span className="company-required-message">
                            {errorMessages.name}
                          </span>
                        )}
                      </div>
                      <div className="company-form-field">
                        <label className="title-company-inputs">
                          Phone
                          <input
                            type="number"
                            name="phone"
                            className="inputs-company-form"
                            placeholder="123-456-7890"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.phone && (
                          <span className="company-required-message">
                            {errorMessages.phone}
                          </span>
                        )}
                      </div>
                      <div className="company-form-field">
                        <label className="title-company-inputs">
                          Email
                          <input
                            type="email"
                            name="email"
                            className="inputs-company-form"
                            placeholder="example@email.com"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.email && (
                          <span className="company-required-message">
                            {errorMessages.email}
                          </span>
                        )}
                      </div>
                      <div className="company-form-field">
                        <label className="title-company-inputs">
                          Industry sector
                          <select
                            name="sector"
                            className="selects-company-form"
                            value={formData.sector}
                            onChange={handleInputChange}
                            required
                          >
                            <option value=""></option>
                            {sapIndustries.map((sector, i) => (
                              <option value={sector} key={"sector" + i}>
                                {sector}
                              </option>
                            ))}
                          </select>
                        </label>
                        {errorMessages.sector && (
                          <span className="company-required-message">
                            {errorMessages.sector}
                          </span>
                        )}
                      </div>

                      <div className="company-container-btn">
                        <Button
                          className="company-continue-btn btn-primary"
                          onClick={handleContinue}
                        >
                          Continue
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
                {step === 2 && (
                  <div className="company-modal-content">
                    {/* <div className="company-form-txt">
                      <p>Which SAP modules are used in your company?:</p>
                    </div>
                    <form className="company-continue-form">
                      <SAPModulesForm
                        onModulesSelected={handleModulesSelected}
                        selectedModules={selectedModules}
                      /> */}
                      <div className="company-container-btn">
                        <Button className="btn-secondary" onClick={handleBack}>
                          Back
                        </Button>
                        <Button className="btn-primary" onClick={handleSave}>
                          Save changes
                        </Button>
                      </div>
                    {/* </form> */}
                  </div>
                )}
              </div>
              <div className="img-form-company"></div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
