import React, { useState } from "react";
import "./Card.css";

// material ui
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import Rating from "@mui/material/Rating";

// components
import AvatarAser from "../../components/Avatar/Avatar";
import ModalContactConsultant from "../../components/ModalContactConsultant/ModalContactConsultant";

// images
// import ImageUserReview from "../../assets/images/G1.jpg";

//icons
import { FaRegStar } from "react-icons/fa";
// import { IoIosArrowForward } from "react-icons/io";
import { MdContactMail } from "react-icons/md";

export default function MediaCard({ consultant, index }) {
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleOpenContact = () => {
    setIsOpen(true);
  };

  return (
    <Card
      className={`card-container ${isFocused ? "focused" : ""}`}
      tabIndex="0"
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
       <button className="review-btn">
          4.5 <FaRegStar className="icon-star" />
        </button>
      <div className="img-review">
       
        <AvatarAser
          size={128}
          avatar={consultant.avatar}
          color={consultant.color}
        />
      </div>
      <div className="certified-container">
        <p className="certified-title">SAP Certified Expert</p>
      </div>

      <CardContent className="card-content">
        <Typography
          gutterBottom
          className="consultant-name"
          variant="subtitle1"
          component="div"
        >
          <Typography
            variant="caption"
            className="experience-txt"
            component="p"
          >
            SAP expert with{" "}
            {consultant.profile && consultant.profile.years
              ? consultant.profile.years
              : "?"}{" "}
            years of experience.{" "}
            {consultant.profile && consultant.profile.modules
              ? consultant.profile.modules.toUpperCase().split(",").join(", ")
              : ""}
            .
          </Typography>
        </Typography>
        {/* <div className="reviews-section">
          <h6>Reviews</h6>
          <Button
            className="see-all-btn"
            endIcon={<IoIosArrowForward className="arrow-see-all" />}
          >
            See all
          </Button>
        </div>

        <div className="reviews-container">
          <div className="user-review-section">
            <div className="user-review">
              <img src={ImageUserReview} alt="UserReview" />
              <div className="user-review-data">
                <p className="name-user-review">Jinny Oslin</p>
                <p className="time-review">A day ago</p>
              </div>
            </div>
            <div className="rating-review">
              <Rating
                name="read-only"
                value={4.5}
                precision={0.5}
                readOnly
                size="small"
              />
            </div>
          </div>
          <div className="review-txt">
            <p>
              Magna id sint irure in cillum esse nisi dolor laboris ullamco...
            </p>
          </div>
        </div> */}
      </CardContent>
      <CardActions>
        <Button
          className="hire-btn btn-secondary"
          startIcon={<MdContactMail className="icon-hire-btn" />}
          onClick={handleOpenContact}
        >
          Contact
        </Button>
      </CardActions>

      <ModalContactConsultant isModalOpen={isOpen} setIsModalOpen={setIsOpen} consultant={consultant} />
    </Card>
  );
}
