import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";

// styles
import "./Login.css";

// material ui
import { Button } from "@mui/material";

// icons
import { AiOutlineGoogle } from "react-icons/ai";
import { SiMicrosoftoutlook } from "react-icons/si";
// import { AiOutlineApple } from "react-icons/ai";

// images
import CogniSapLogo from "../../assets/images/cognisap-logo.png";

// components
import ModalRegisterCompany from "../../components/ModalRegisterCompany/ModalRegisterCompany";
import ModalStatusCompany from "../../components/ModalStatusCompany/ModalStatusCompany";

// utils
import Webservice from "../../utils/Webservice";

export default function Login() {
  // state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  const [code, setCode] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = (url) => {
    window.location.replace(
      "/.auth/login/" + url + "?post_login_redirect_uri=/api/signup"
    );
  };

  const handleLoginCompany = async () => {
    const errors = {};

    if (!formData.email) {
      errors.email = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      console.log(formData);
      setErrorMessages({});
    } else {
      setErrorMessages(errors);
      return;
    }
    setLoading(true);
    Webservice.post("login_ws", {
      route: "loginCompany",
      email: formData.email,
    }).then((result) => {
      console.log(result);
      setLoading(false);
      if (result.code === "P0001") {
        setStep(2);
        return;
      }
      if (result.code === "P0005") {
        setStatus(result.status);
        setCode(result.code);
        setIsModalOpenStatus(true);
        return;
      }

      if (!result.company) {
        setIsModalOpen(true);
      }
    });
  };

  const handleLoginVerify = async () => {
    const errors = {};

    if (!formData.token) {
      errors.token = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      console.log(formData);
      setErrorMessages({});
    } else {
      setErrorMessages(errors);
      return;
    }
    setLoading(true);

    Webservice.post("login_ws", {
      route: "verifyToken",
      token: formData.token,
      email: formData.email,
    }).then((result) => {
      console.log(result);
      setLoading(false);
      if (result.location) {
        window.location.replace(result.location);
      }

      if (result.token && !result.token.valid) {
        errors.token =
          "The tokens are single-use, it is possible that it has already been used or is incorrect.";
        setErrorMessages(errors);
      }
    });
  };

  const handleBack = () => {
    setStep(1);
  };

  return (
    <div className="login-container">
      <ModalRegisterCompany
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <ModalStatusCompany
        isModalOpen={isModalOpenStatus}
        setIsModalOpen={setIsModalOpenStatus}
        status={status}
        code={code}
      />

      <img
        className="cognisap-logo-login"
        src={CogniSapLogo}
        alt="CogniSap Logo"
      />
      <p className="logo-text-helper">
        Your <b className="secondary-color">SAP</b> assistant is here, ask
        anything.
      </p>

      <div className="login-container-modal">
        {/* left-side */}
        <div className="login-left-side">
          <h1>Enterprise</h1>
          <h6>
            If you work a company that is already registered with us, use your
            company email.
          </h6>
          <div>
            <h3 className="signin">Sign in</h3>

            {loading && (
              <div className="loading-page">
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#379AE6FF",
                    "#379AE6FF",
                    "#379AE6FF",
                    "#379AE6FF",
                    "#379AE6FF",
                  ]}
                />
              </div>
            )}

            {!loading && step === 1 && (
              <div className="providers-btns">
                <p>Corporate email</p>
                <div className="consultant-form-field">
                  <input
                    type="text"
                    name="email"
                    className="input-form"
                    placeholder="email@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errorMessages.email && (
                    <span className="consultant-required-message">
                      * {errorMessages.email}
                    </span>
                  )}
                </div>
                <Button
                  onClick={() => handleLoginCompany()}
                  variant="contained"
                  className="login-btn get-stated-btn"
                >
                  Get Started
                </Button>

                <Button
                  variant="outline"
                  className="login-btn register-company-btn"
                  onClick={() => setIsModalOpen(true)}
                >
                  Register Company
                </Button>
              </div>
            )}

            {!loading && step === 2 && (
              <div className="providers-btns">
                <p>
                  We have sent a token to the email "{formData.email}" enter it
                  to continue
                </p>

                <div className="consultant-form-field">
                  <input
                    type="text"
                    name="token"
                    className="input-form"
                    placeholder="Insert token"
                    value={formData.token}
                    onChange={handleInputChange}
                  />
                  {errorMessages.token && (
                    <span className="consultant-required-message">
                      * {errorMessages.token}
                    </span>
                  )}
                </div>
                <Button
                  onClick={() => handleLoginVerify()}
                  variant="contained"
                  className="login-btn get-stated-btn"
                >
                  Validate Token
                </Button>

                <Button
                  variant="outline"
                  className="login-btn register-company-btn"
                  onClick={handleLoginCompany}
                >
                  Resend Token
                </Button>

                <div className="link-back" onClick={handleBack}>
                  Go Home
                </div>
              </div>
            )}
          </div>
        </div>

        {/* right-side */}
        <div className="login-right-side">
          <h1>Consultant</h1>
          <h6>
            If you are a SAP consultant or want to use our assistant with AI,
            log in here.
          </h6>
          <div>
            <h3 className="signin">Sign in</h3>

            <p>Select your identity provider</p>

            <div className="providers-btns">
              <Button
                onClick={() => handleLogin("google")}
                variant="contained"
                startIcon={<AiOutlineGoogle />}
                className="login-btn google-btn"
              >
                Google
              </Button>

              <Button
                onClick={() => handleLogin("aad")}
                variant="contained"
                startIcon={<SiMicrosoftoutlook />}
                className="login-btn microsoft-btn"
              >
                Microsoft
              </Button>
              {/* 
              <Button
                variant="contained"
                startIcon={<AiOutlineApple />}
                className="login-btn apple-btn"
              >
                Apple
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
