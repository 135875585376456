import React, { useState, useEffect } from "react";

// styles
import "./ModalStatusCompany.css";

// material ui
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

// icons
import { FcInfo } from "react-icons/fc";
import { SlHome } from "react-icons/sl";

export default function ModalStatusCompany({
  isModalOpen,
  setIsModalOpen,
  code,
  status,
}) {
  // states
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  useEffect(() => {
    if (code === "P0005") {
      setResponseMessage(
        "The company is registered but is awaiting approval, please wait for the response via the email you provided."
      );
      setResponseStatus(status);
    }
  }, [code, status]);

  return (
    <div>
      <Modal
        open={isModalOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box className="register-company-container">
            <div className="modal-register-company">
              <div className="register-company-form">
                <div className="register-company-modal-content">
                  <div className="register-company-check-container">
                    <FcInfo className="check-icon" />
                  </div>
                  <div className="register-company-txt secondary-color">
                    <p>Registration Information</p>
                  </div>
                  <div className="register-company-form-txt">
                    <p>{responseMessage}</p>
                  </div>
                  <div
                    className={
                      "register-company-txt primary-color " +
                      (status === "Rejected" ? "rejected-color" : "")
                    }
                  >
                    <p>
                      <h4>Company Status:</h4> {responseStatus}
                    </p>
                  </div>
                  <div className="register-company-container-btn">
                    <Button
                      className="register-btn btn-secondary"
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                      startIcon={<SlHome size={15} />}
                    >
                      Back to Home
                    </Button>
                  </div>
                </div>
              </div>
              <div className="img-form-register-company"></div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
