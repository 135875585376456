import React, { useState, useContext, useEffect } from "react";

// context
import { AuthContext } from "../../context/AuthContext";

// styles
import "./Avatar.css";

// avatars
import avatar1 from "../../assets/images/avatars/1.png";
import avatar2 from "../../assets/images/avatars/2.png";
import avatar3 from "../../assets/images/avatars/3.png";
import avatar4 from "../../assets/images/avatars/4.png";
import avatar5 from "../../assets/images/avatars/5.png";
import avatar6 from "../../assets/images/avatars/6.png";
import avatar7 from "../../assets/images/avatars/7.png";
import avatar8 from "../../assets/images/avatars/8.png";
import avatar9 from "../../assets/images/avatars/9.png";
import avatar10 from "../../assets/images/avatars/10.png";
import avatar11 from "../../assets/images/avatars/11.png";
import avatar12 from "../../assets/images/avatars/12.png";
import avatar13 from "../../assets/images/avatars/13.png";
import avatar14 from "../../assets/images/avatars/14.png";
import avatar15 from "../../assets/images/avatars/15.png";
import avatar16 from "../../assets/images/avatars/16.png";

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
];

export default function Avatar({ size, color, avatar }) {
  // context
  const { userData, isAuthenticated } = useContext(AuthContext);

  // state
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#fff");

  useEffect(() => {
    if (isAuthenticated && userData && (!color || !avatar)) {
      if (userData && userData.avatar) {
        setSelectedAvatarIndex(userData.avatar);
        setSelectedAvatar(avatars[userData.avatar]);
        setSelectedColor(userData.color);
      }
    }
  }, [userData, isAuthenticated, color, avatar, setSelectedAvatarIndex]);

  useEffect(() => {
    if (color && avatar) {
      setSelectedAvatarIndex(avatar);
      setSelectedAvatar(avatars[avatar]);
      setSelectedColor(color);
    }
  }, [color, avatar, setSelectedAvatarIndex]);

  return (
    <div
      id={selectedAvatarIndex}
      className="avatar-container"
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div
        className="avatar-color"
        style={{ backgroundColor: selectedColor }}
      ></div>
      <img src={selectedAvatar} className="avatar-img" alt="" />
    </div>
  );
}
