import React from "react";

// styles
import "./Consultants.css";

// components
import CarouselCards from "../../components/CarouselCards/CarouselCards";
import Footer from "../../components/Footer/Footer";

export default  function Consultants() {
  return (
    <div className="consultants">
      {/* <CutSearch /> */}
      <div className="consultants-body">
        <div className="title-carousel">
          <h1>Are you looking for a SAP consultant?</h1>
          <p>
            These skilled professionals are readily available and enthusiastic
            about providing you with the assistance and expertise you need.
          </p>
          <h3 className="subtitle">Prominent Consultants</h3>
        </div>

        <div className="consultants-container">
          <CarouselCards />
        </div>
        <Footer />
      </div>
    </div>
  );
}
