import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// views
import Chat from "./views/Chat/Chat";
import Login from "./views/Login/Login";
import ChatAssistant from "./views/ChatAssistant/ChatAssistant";
import Home from "./views/Home/Home";
import Consultants from "./views/Consultants/Consultants";
import Forum from "./views/Forum/Forum";
import ConsultantProfile from "./views/ConsultantProfile/ConsultantProfile";
import CompanyProfile from "./views/CompanyProfile/CompanyProfile";
import ChooseAvatar from "./views/ChooseAvatar/ChooseAvatar";
import AdminCompanies from "./views/AdminCompanies/AdminCompanies";
import AdminConsultants from "./views/AdminConsultants/AdminConsultants";

// components
import ModalUserType from "./components/ModalUserType/ModalUserType";
import AppBar from "./components/AppBar/AppBar";
import Footer from "./components/Footer/Footer";
import Question from "./components/Question/Question";

function App() {
  return (
    <BrowserRouter>
      <AppBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chat-assistant" element={<ChatAssistant />} />
        <Route path="/consultants" element={<Consultants />} />
        <Route path="/consultant-profile" element={<ConsultantProfile />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route path="/avatars" element={<ChooseAvatar />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forum/:questionId" element={<Question />} />
        <Route path="/user-selection" element={<ModalUserType />} />
        <Route path="/admin/companies" element={<AdminCompanies />} />
        <Route path="/admin/consultants" element={<AdminConsultants />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
