import React, { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";

// styles
import "./ModalContactConsultant.css";

// utils
import Webservice from "../../utils/Webservice";

// components
import AvatarAser from "../../components/Avatar/Avatar";

// material ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";

export default function ModalContactConsultant({
  isModalOpen,
  setIsModalOpen,
  consultant,
}) {
  // states
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    observation: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    observation: "",
  });

  // effect
  useEffect(() => {
    if (isModalOpen) {
      setLoading(false);
      setOpen(false);
      setFormData({
        observation: "",
      });
      setErrorMessages({
        observation: "",
      });
    }
  }, [isModalOpen]);

  // methods
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = () => {
    const errors = {};

    if (!formData.observation) {
      errors.observation = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      setErrorMessages({});
      handleSave();
    } else {
      setErrorMessages(errors);
    }
  };

  const handleSave = () => {
    setLoading(true);
    const saveData = async () => {
      await Webservice.post("consultants", {
        route: "contact",
        ...formData,
        consultant: consultant.userId,
      });

      setOpen(true);

      setTimeout(() => {
        setIsModalOpen(false);
        setOpen(false);
      }, 4000);
    };
    saveData();
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box className="consultant-contact-container">
            <div className="modal-consultant-contact">
              <div className="modal-consultan-form">
                <p className="title-consultant-modal">Contact me</p>
                <div className="consultant-modal-content">
                  <div className="consultant-modal-img-review">
                    <AvatarAser
                      size={90}
                      avatar={consultant.avatar}
                      color={consultant.color}
                    />
                  </div>
                  <div className="consultant-contact-txt">
                    <Typography
                      variant="caption"
                      className="experience-txt"
                      component="p"
                    >
                      SAP expert with{" "}
                      {consultant.profile && consultant.profile.years
                        ? consultant.profile.years
                        : "?"}{" "}
                      years of experience.{" "}
                      {consultant.profile && consultant.profile.modules
                        ? consultant.profile.modules
                            .toUpperCase()
                            .split(",")
                            .join(", ")
                        : ""}
                      .
                    </Typography>
                  </div>

                  {!loading && (
                    <form className="consultant-contact-modal">
                      <div className="consultant-contact-field">
                        <label className="title-consultant-inputs">
                          Message
                          <textarea
                            type="text"
                            name="observation"
                            className="inputs-consultant-contact"
                            placeholder="Briefly describe your situation or what you're looking to improve in your SAP system..."
                            value={formData.observation}
                            rows={10}
                            onChange={handleInputChange}
                          />
                        </label>
                        {errorMessages.observation && (
                          <span className="consultant-required-message">
                            {errorMessages.observation}
                          </span>
                        )}
                      </div>

                      <div className="consultant-container-btn">
                        <Button
                          className="btn-primary"
                          onClick={handleContinue}
                        >
                          Send Message
                        </Button>

                        <Button
                          className="btn-secondary"
                          onClick={() => setIsModalOpen(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </form>
                  )}

                  {loading && (
                    <div className="loading-page">
                      <ColorRing
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#379AE6FF",
                          "#379AE6FF",
                          "#379AE6FF",
                          "#379AE6FF",
                          "#379AE6FF",
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        className="alert-success"
        open={open}
        message="Message sent!"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </div>
  );
}
