// AuthContext.js
import React, { createContext, useState, useEffect } from "react";

// utils
import Webservice from "../utils/Webservice";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // state
  const [userData, setUserData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(false);

    const fetchData = async () => {
      const url = "/.auth/me";

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Request error:: ${response.status}`);
        }

        const data = await response.json();
        if (data.clientPrincipal) {
          const email = decodeURIComponent(data.clientPrincipal.userDetails);
          const nameClaim = data.clientPrincipal.claims.find(
            (claim) => claim.typ === "name"
          );
          const name = nameClaim ? decodeURIComponent(nameClaim.val) : "";
          const userId = data.clientPrincipal.userId;

          setUserData({
            email,
            name,
            userId,
          });

          const response = await Webservice.post("signup", {
            route: "getLogged",
          });

          if (response && response.user) {
            setUserData({
              ...response.user,
            });
          }

          setIsAuthenticated(true);
        } else {
          const response = await Webservice.post("signup", {
            route: "getLoggedCookie",
          });

          if (response && response.user) {
            setUserData({
              ...response.user,
            });
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            if (
              !window.location.href.includes("/login") &&
              window.location.pathname !== "/"
            ) {
              window.location.href = "/login";
            }
          }
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.log(window.location.href);
        if (
          !window.location.href.includes("/login") &&
          window.location.pathname !== "/"
        ) {
          window.location.href = "/login";
        }
        console.error("Error al obtener la información: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updateUser = async () => {
      await Webservice.put("signup", userData);
    };
    // Solo llama a actualizarUsuario si el usuario existe
    if (userData) {
      updateUser();
    }
  }, [userData]);

  const reloadUser = () => {
    const updateUser = async () => {
      const response = await Webservice.post("signup", {
        route: "getLogged",
      });

      if (response && response.user) {
        setUserData({
          ...response.user,
        });
      }
    };
    updateUser();
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        isAuthenticated,
        setIsAuthenticated,
        setUserData,
        reloadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
