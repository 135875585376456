import React, { useState, useEffect, useContext } from "react";
import "./ChatHistory.css";

// context
import { AuthContext } from "../../context/AuthContext";

// icons
import { IoAddOutline } from "react-icons/io5";
import { MdHistory } from "react-icons/md";

// material ui
import { Button } from "@mui/material";

// utils
import Webservice from "../../utils/Webservice";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default function ChatHistory({ isDoingSearch, onNewChat, onLoadChat }) {
  const [messages, setMessages] = useState([]);
  const [active, setActive] = useState(null);
  const [openHistory, setOpenHistory] = useState(false);

  // context
  const { isAuthenticated, userData } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated && userData) {
      Webservice.post("ChatAssistant", {
        route: "getHistoryList",
      }).then((result) => {
        console.log(result.body.list);
        setMessages(result.body.list);
      });
    }
  }, [isDoingSearch, isAuthenticated, userData]);

  const handleChat = async (threadId) => {
    Webservice.post("ChatAssistant", {
      route: "getChatHistory",
      threadId,
    }).then((result) => {
      onLoadChat(result.body.list, threadId);
    });
  };

  return (
    <div className={"chat-history " + (openHistory? 'chat-open': '')} >
      <div className="chat-history-btns">
        <Button
          className="btn-primary btn-chat"
          variant="contained"
          onClick={onNewChat}
          startIcon={<IoAddOutline className="btn-chat-icon" />}
        >
          New Chat
        </Button>
        <Button
          className="btn-primary btn-chat btn-open-history"
          variant="contained"
          onClick={()=>{
            setOpenHistory(!openHistory)
          }}
        >
          <MdHistory size={32} />
        </Button>
      </div>
      <h3>
        <MdHistory /> Chat History
      </h3>
      <ul className="chat-history-list">
        {!messages.length && (
          <div>Hello! There are no inquiries in your history yet.</div>
        )}

        {messages.map((message, index) => (
          <li
            className={
              "chat-history-item " +
              (active === index ? "chat-history-item-active" : "")
            }
            key={index}
            onClick={() => {
              handleChat(message.threadId);
              setActive(index);
            setOpenHistory(false)
          }}
          >
            {message.message && (
              <div className="chat-history-item-text">
                {message.message.substring(0, 50)}...
              </div>
            )}
            {message.response && (
              <div className="chat-history-item-detail">
                {message.response.substring(0, 80)}...
              </div>
            )}
            <div className="chat-history-item-time">
              {dayjs(message.timestamp).fromNow()}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
