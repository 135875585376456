import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";

// styles
import "./ModalRegisterCompany.css";

// material ui
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

// utils
import Webservice from "../../utils/Webservice";

// icons
import { FaCircleCheck } from "react-icons/fa6";
import { SlHome } from "react-icons/sl";

export default function ModalRegisterCompany({ isModalOpen, setIsModalOpen }) {
  // states
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [shouldClose] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    username: "",
    nif: "",
    phone: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    email: "",
    name: "",
    username: "",
    nif: "",
    phone: "",
  });

  // methods
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = "Required field";
    }

    if (!formData.name) {
      errors.name = "Required field";
    }

    if (!formData.nif) {
      errors.nif = "Required field";
    }

    if (!formData.phone) {
      errors.phone = "Required field";
    }

    if (!formData.username) {
      errors.username = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      console.log(formData);
      setErrorMessages({});

      const saveData = async () => {
        const response = await Webservice.post("login_ws", {
          route: "registerCompany",
          ...formData,
        });

        if (response.code === "P0003") {
          setResponseMessage(
            "The company is already registered, wait for the response via the email you provided."
          );

          setResponseStatus(response.status);
        }
        if (response.code === "P0004") {
          setResponseMessage(
            "We have sent an email informing the company that you want to register your employees with them, wait for the response through the email you provided."
          );
        }

        setLoading(false);
        setStep(2);
      };

      saveData();
      setLoading(true);
    } else {
      setErrorMessages(errors);
    }
  };

  const handleCancel = () => {
    setFormData({
      email: "",
      name: "",
      nif: "",
      phone: "",
      username: "",
    });

    setErrorMessages({
      email: "",
      name: "",
      nif: "",
      phone: "",
      username: "",
    });

    setStep(1);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={shouldClose ? handleContinue : () => {}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box className="register-company-container">
            <div className="modal-register-company">
              <div className="register-company-form">
                {step === 1 && !loading && (
                  <div>
                    <p className="title-register-company">
                      Register your company
                    </p>
                    <div className="register-company-form-txt">
                      <p>
                        Complete the form below to take the first step towards
                        optimizing and transforming your business processes with
                        SAP.
                      </p>
                    </div>

                    <form className="register-company-form-modal">
                      <div className="register-company-form-field">
                        <label className="title-register-company-inputs">
                          Corporate email (example@email.com)
                          <input
                            type="email"
                            name="email"
                            className="inputs-register-company-form"
                            placeholder="example@email.com"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.email && (
                          <span className="register-company-required-message">
                            {errorMessages.email}
                          </span>
                        )}
                      </div>

                      <div className="register-company-form-field">
                        <label className="title-register-company-inputs">
                          Full company name
                          <input
                            type="text"
                            name="name"
                            className="inputs-register-company-form"
                            placeholder="CogniSAP"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.name && (
                          <span className="register-company-required-message">
                            {errorMessages.name}
                          </span>
                        )}
                      </div>
                      <div className="register-company-form-field">
                        <label className="title-register-company-inputs">
                          Tax Identification Number (NIF)
                          <input
                            type="text"
                            name="nif"
                            pattern="[0-9\-]*"
                            className="inputs-register-company-form"
                            placeholder="NNN-NN-NNNN"
                            value={formData.nif}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.nif && (
                          <span className="register-company-required-message">
                            {errorMessages.nif}
                          </span>
                        )}
                      </div>
                      <div className="register-company-form-field">
                        <label className="title-register-company-inputs">
                          Enter your full name
                          <input
                            type="text"
                            name="username"
                            className="inputs-register-company-form"
                            placeholder=""
                            value={formData.username}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.username && (
                          <span className="register-company-required-message">
                            {errorMessages.username}
                          </span>
                        )}
                      </div>
                      <div className="register-company-form-field">
                        <label className="title-register-company-inputs">
                          Phone
                          <input
                            type="text"
                            name="phone"
                            className="inputs-register-company-form"
                            //placeholder="example@email.com"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        {errorMessages.phone && (
                          <span className="register-company-required-message">
                            {errorMessages.phone}
                          </span>
                        )}
                      </div>

                      <div className="register-company-container-btn">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="register-btn register-company-btn"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="register-company-continue-btn btn-primary"
                          onClick={handleContinue}
                        >
                          Continue
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
                {step === 2 && !loading && (
                  <div className="register-company-modal-content">
                    <div className="register-company-check-container">
                      <FaCircleCheck className="check-icon" />
                    </div>
                    <div className="register-company-txt secondary-color">
                      <p>Successful registration</p>
                    </div>
                    <div className="register-company-form-txt">
                      <p>{responseMessage}</p>
                    </div>
                    <div className="register-company-txt primary-color">
                      <p>Status: {responseStatus}</p>
                    </div>
                    <div className="register-company-container-btn">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="register-btn register-company-btn"
                        onClick={() => setStep(1)}
                      >
                        Back
                      </Button>

                      <Button
                        className="register-btn btn-secondary"
                        onClick={() => {
                          setIsModalOpen(false);
                        }}
                        startIcon={<SlHome size={15} />}
                      >
                        Back to Home
                      </Button>
                    </div>
                  </div>
                )}

                {loading && (
                  <div className="loading-page">
                    <ColorRing
                      visible={true}
                      height="40"
                      width="40"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={[
                        "#379AE6FF",
                        "#379AE6FF",
                        "#379AE6FF",
                        "#379AE6FF",
                        "#379AE6FF",
                      ]}
                    />
                  </div>
                )}
              </div>
              <div className="img-form-register-company"></div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
