import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// styles
import "./ModalUserType.css";

// context
import { AuthContext } from "../../context/AuthContext";

// material ui
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

// images
import CompanyImage from "../../assets/images/company.png";
import ConsultantImage from "../../assets/images/consultant.png";

export default function ModalUserType() {
  const navigate = useNavigate();

  // states
  const [isModalOpen, setIsModalOpen] = useState(true);
  // const [shouldClose, setShouldClose] = useState(false);
  const { setUserData, userData } = useContext(AuthContext);
  // const [selectedUserType, setSelectedUserType] = useState(null); // Nuevo estado para almacenar el tipo de usuario seleccionado

  const handleSave = (userType) => {
    // Almacenar el tipo de usuario en el localStorage
    localStorage.setItem("userType", userType);

    setUserData({
      ...userData,
      rol: userType,
    });
    setIsModalOpen(false);
    setTimeout(() => {
      navigate("/chat-assistant");
    }, 1000);
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box className="modal-chat-container">
            <div className="modal-company-consultant">
              <div className="login-companies">
                <p className="title-modal">Sign in for Companies</p>
                <div className="image-modal-container">
                  <img
                    className="modal-type-img"
                    src={CompanyImage}
                    alt="company"
                  />
                </div>
                <div className="question-modal-txt">
                  <p>Are you a Company?</p>
                </div>
                <div className="description-modal-txt">
                  <p>
                    You are involved in variety of sectors, such as
                    manufactoring, financial services, healthcare, retail, among
                    others. And you use SAP applications.
                  </p>
                </div>
                <div className="login-btn-container">
                  <Button
                    onClick={() => handleSave("COMPANY")}
                    className="btn-primary"
                  >
                    Sign in as a company
                  </Button>
                </div>
              </div>
              <div className="login-consultants">
                <p className="title-modal">Sign in for Consultants</p>
                <div className="image-modal-container">
                  <img
                    className="modal-type-img"
                    src={ConsultantImage}
                    alt="consultant"
                  />
                </div>
                <div className="question-modal-txt">
                  <p>Are you a Consultant?</p>
                </div>
                <div className="description-modal-txt">
                  <p>
                    You are a profesional specialized in the implementation,
                    configuration, and maintenance of software solutions
                    provided by SAP, one of the leading companies in enterprise
                    software. You have a deep understanding of SAP modules and
                    how they integrate into a company's business processes.
                  </p>
                </div>
                <div className="login-btn-container">
                  <Button
                    onClick={() => handleSave("CONSULTANT")}
                    className="btn-primary"
                  >
                    Sign in as a Consultant
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
