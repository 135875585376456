import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/colors.css";
import "./assets/css/index.css";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { QueryProvider } from "./context/QueryContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <QueryProvider>
      <App />
    </QueryProvider>
  </AuthProvider>
);
