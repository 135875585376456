import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AppBar.css";

// context
import { AuthContext } from "../../context/AuthContext";

// icons
import MenuIcon from "@mui/icons-material/Menu";
import { BiLogOut } from "react-icons/bi";
import { VscAccount } from "react-icons/vsc";
// import { MdOutlineModeNight } from "react-icons/md";

// utils
import Webservice from "../../utils/Webservice";

// images
import CogniSapLogo from "../../assets/images/cognisap-logo.png";

// components
import AvatarAser from "../../components/Avatar/Avatar";

// material ui
import {
  ListItemIcon,
  Divider,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  // Switch,
  // styled,
} from "@mui/material";

// components
import ModalFormCompany from "../../components/ModalFormCompany/ModalFormCompany";
import ModalFormConsultant from "../../components/ModalFormConsultant/ModalFormConsultant";

export default function ResponsiveAppBar() {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = window.screen.availWidth < 768;

  // context
  const { isAuthenticated, setIsAuthenticated, userData } =
    useContext(AuthContext);

  // state
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isModalFormCompanyOpen, setIsModalFormCompanyOpen] = useState(false);
  const [isModalFormConsultantOpen, setIsModalFormConsultantOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    Webservice.post("signup", {
      route: "logout",
    }).then((result) => {
      // Simulación de cierre de sesión
      if (userData.identityProvider) {
        window.location.replace("/logout?post_logout_redirect_uri=/");
      } else {
        navigate("/");
      }
      localStorage.removeItem("searchQuery");
      localStorage.removeItem("userType");
      setIsAuthenticated(false);
    });
  };

  // Abre el menú de navegación cuando se hace clic en un elemento que lo despliega
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  // Cierra el menú de navegación
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleProfile = () => {
    if (userData.rol === "CONSULTANT") {
      navigate("/consultant-profile");
    }

    if (userData.rol === "COMPANY") {
      navigate("/company-profile");
    }
  };

  const handleOpenRegister = () => {
    if (userData.rol === "CONSULTANT") {
      setIsModalFormConsultantOpen(true);
    }

    if (userData.rol === "COMPANY") {
      setIsModalFormCompanyOpen(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleNightMode = (event) => {
  //   const isNightMode = event.target.checked;

  //   if (isNightMode) {
  //     document.body.classList.add("night-mode");
  //   } else {
  //     document.body.classList.remove("night-mode");
  //   }
  // };

  // Estado y funcion del switch para el dark mode en el menú
  // const ModeSwitch = styled((props) => (
  //   <Switch
  //     focusVisibleClassName=".Mui-focusVisible"
  //     disableRipple
  //     {...props}
  //   />
  // ))(({ theme }) => ({
  //   width: 42,
  //   height: 26,
  //   padding: 0,
  //   "& .MuiSwitch-switchBase": {
  //     padding: 0,
  //     margin: 2,
  //     transitionDuration: "300ms",
  //     "&.Mui-checked": {
  //       transform: "translateX(16px)",
  //       color: "#fff",
  //       "& + .MuiSwitch-track": {
  //         backgroundColor:
  //           theme.palette.mode === "dark" ? "#197DCAFF" : "#197DCAFF",
  //         opacity: 1,
  //         border: 0,
  //       },
  //       "&.Mui-disabled + .MuiSwitch-track": {
  //         opacity: 0.5,
  //       },
  //     },
  //     "&.Mui-focusVisible .MuiSwitch-thumb": {
  //       color: "#197DCAFF",
  //       border: "6px solid #fff",
  //     },
  //     "&.Mui-disabled .MuiSwitch-thumb": {
  //       color:
  //         theme.palette.mode === "light"
  //           ? theme.palette.grey[100]
  //           : theme.palette.grey[600],
  //     },
  //     "&.Mui-disabled + .MuiSwitch-track": {
  //       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
  //     },
  //   },
  //   "& .MuiSwitch-thumb": {
  //     boxSizing: "border-box",
  //     width: 22,
  //     height: 22,
  //   },
  //   "& .MuiSwitch-track": {
  //     borderRadius: 26 / 2,
  //     backgroundColor: theme.palette.mode === "light" ? "#EBFDFF" : "#39393D",
  //     opacity: 1,
  //     transition: theme.transitions.create(["background-color"], {
  //       duration: 500,
  //     }),
  //   },
  // }));

  return (
    <div>
      {isModalFormCompanyOpen && (
        <ModalFormCompany
          isModalOpen={isModalFormCompanyOpen}
          setIsModalOpen={setIsModalFormCompanyOpen}
        />
      )}
      {isModalFormConsultantOpen && (
        <ModalFormConsultant
          isModalOpen={isModalFormConsultantOpen}
          setIsModalOpen={setIsModalFormConsultantOpen}
        />
      )}

      {isAuthenticated && userData && userData.rol && (
        <AppBar position="static" className="appbar">
          <Container maxWidth="xl" className="appbar-container">
            <Toolbar disableGutters className="appbar-toolbar">
              {/* Menú desplegable de navegación en dispositivos móviles */}
              <Box className="menu-container menu-mobile">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="#565E6C"
                >
                  <MenuIcon />
                </IconButton>
                {/* Menú de navegación para dispositivos móviles */}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  className="menu-appbar menu-mobile-box"
                >
                  {/* Genera los elementos del menú izquierdo del AppBar (Header) */}

                  <Button
                  onClick={() => {setAnchorElNav(null); navigate("chat-assistant");} }
                  className={`menu-item-web ${
                    location.pathname === "/chat-assistant" ? "selected" : ""
                  }`}
                >
                  Search
                </Button>
                {userData.rol === "ADMIN" ||
                  (userData.rol === "COMPANY" && (
                    <Button
                      onClick={() => {setAnchorElNav(null); navigate("/consultants");}}
                      className={`menu-item-web ${
                        location.pathname === "/consultants" ? "selected" : ""
                      }`}
                    >
                      I need a SAP consultant
                    </Button>
                  ))}
                {userData.rol === "ADMIN" ||
                  (userData.rol === "CONSULTANT" && (
                    <Button
                      onClick={() => {setAnchorElNav(null); navigate("/forum");}}
                      className={`menu-item-web ${
                        location.pathname === "/forum" ? "selected" : ""
                      }`}
                    >
                      Forum
                    </Button>
                  ))}
                {userData.rol === "ADMIN" && (
                  <Button
                    onClick={() => {setAnchorElNav(null); navigate("/admin/companies");} }
                    className={`menu-item-web ${
                      location.pathname === "/admin/companies" ? "selected" : ""
                    }`}
                  >
                    Companies
                  </Button>
                )}

                {userData.rol === "ADMIN" && (
                  <Button
                    onClick={() => {setAnchorElNav(null); navigate("/admin/consultants")}} 
                    className={`menu-item-web ${ location.pathname === "/admin/consultants" ? "selected": ""}`}
                  >
                    Consultants
                  </Button>
                )}

                  
                </Menu>
              </Box>
              { (userData?.profile || !isMobile) && (
              <div onClick={() => navigate("/")} className="cognisap-logo-link">
                <img
                  src={CogniSapLogo}
                  alt="CogniSap Logo"
                  className="cognisap-logo"
                  style={{
                    display: { xs: "flex", md: "none" },
                    mr: 1,
                    width: "160px",
                    marginRight: "16px",
                  }}
                />
              </div>)}

              {/* Botones de navegación en la barra de herramientas */}
              <Box className="navigation-bar">
                <Button
                  onClick={() => navigate("/chat-assistant")}
                  className={`menu-item-web ${
                    location.pathname === "/chat-assistant" ? "selected" : ""
                  }`}
                >
                  Search
                </Button>
                {userData.rol === "ADMIN" ||
                  (userData.rol === "COMPANY" && (
                    <Button
                      onClick={() => navigate("/consultants")}
                      className={`menu-item-web ${
                        location.pathname === "/consultants" ? "selected" : ""
                      }`}
                    >
                      I need a SAP consultant
                    </Button>
                  ))}
                {userData.rol === "ADMIN" ||
                  (userData.rol === "CONSULTANT" && (
                    <Button
                      onClick={() => navigate("/forum")}
                      className={`menu-item-web ${
                        location.pathname === "/forum" ? "selected" : ""
                      }`}
                    >
                      Forum
                    </Button>
                  ))}
                {userData.rol === "ADMIN" && (
                  <Button
                    onClick={() => navigate("/admin/companies")}
                    className={`menu-item-web ${
                      location.pathname === "/admin/companies" ? "selected" : ""
                    }`}
                  >
                    Companies
                  </Button>
                )}

                {userData.rol === "ADMIN" && (
                  <Button
                    onClick={() => navigate("/admin/consultants")}
                    className={`menu-item-web ${
                      location.pathname === "/admin/consultants"
                        ? "selected"
                        : ""
                    }`}
                  >
                    Consultants
                  </Button>
                )}
              </Box>

              {/* Botón de avatar y menú desplegable de cuenta */}
              <Box className="menu-dropdown-container">
                <div className="menu-dropdown-inline">
                  {!userData?.profile && (
                    <div className="register-link" onClick={handleOpenRegister}>
                      Complete your profile
                    </div>
                  )}

                  <Tooltip title="Open settings">
                    <IconButton onClick={handleClick} className="avatar-btn">
                      {userData.avatar ? (
                        <AvatarAser size={36} />
                      ) : (
                        <Avatar alt="Remy Sharp" />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  //className="menu-dropdown"
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      color: "#565E6C",
                      overflow: "visible",
                      filter: "drop-shadow(0px 3px 3px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* Elementos de menú de la cuenta de usuario */}
                  <MenuItem
                    onClick={handleClose && handleProfile}
                    className="menu-dropdown-items"
                  >
                    {userData.avatar ? (
                      <AvatarAser size={48} />
                    ) : (
                      <Avatar alt="Remy Sharp" />
                    )}
                    <div>
                      <Typography className="user-name">
                        {userData?.profile?.name || "Guest"}{" "}
                        {/* Verificación para nameUser */}
                      </Typography>
                      <Typography className="user-email">
                        {userData?.email || "guest@example.com"}{" "}
                        {/* Verificación para emailUser */}
                      </Typography>
                    </div>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleClose && handleProfile}
                    className="menu-dropdown-items"
                  >
                    <ListItemIcon>
                      <VscAccount
                        className="EditProfileIcon"
                        fontSize="large"
                      />
                    </ListItemIcon>
                    Edit profile
                  </MenuItem>
                  <Divider />
                  {/* <MenuItem className="menu-dropdown-items">
                    <ListItemIcon>
                      <MdOutlineModeNight
                        className="ModeIcon"
                        fontSize="large"
                      />
                    </ListItemIcon>
                    Night mode
                    <ModeSwitch sx={{ m: 1 }} onChange={handleNightMode} />
                  </MenuItem> */}

                  <Divider />
                  {isAuthenticated && (
                    <MenuItem
                      onClick={handleClose && handleLogout}
                      className="menu-dropdown-items"
                    >
                      <ListItemIcon>
                        <BiLogOut className="SignOutIcon" fontSize="large" />
                      </ListItemIcon>
                      Sign out
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </div>
  );
}
