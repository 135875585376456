import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// styles
import "./Home.css";

// context
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "../../context/QueryContext";

// components
import Footer from "../../components/Footer/Footer";
import Login from "../../components/LoginRegister/Login";

// icons
import { IoMdSearch } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import { BsMic } from "react-icons/bs";

// material ui
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";

// images
import CogniSapLogo from "../../assets/images/cognisap-logo.png";

const Home = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const { query, setQuery } = useQuery();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const LoginPopupRef = useRef(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Mostrar el popup si la ruta es "/login"
  //   setShowLoginPopup(window.location.pathname === "/login");
  // }, []);

  useEffect(() => {
    // Guardar la consulta en localStorage cuando cambie
    localStorage.setItem("searchQuery", query);
  }, [query]);

  useEffect(() => {
    // Obtener el valor almacenado en localStorage
    const storedQuery = localStorage.getItem("searchQuery");

    // Mostrar en la consola
    console.log("Valor almacenado en localStorage:", storedQuery);
  }, []);

  const handleLogin = () => {
    // Simulación de inicio de sesión exitoso
    setIsAuthenticated(true);
    setShowLoginPopup(false);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      // Realiza la búsqueda cuando se presiona "Enter"
      performSearch();
    }
  };

  // Empty message ----------------------------
  const [isEmpty, setIsEmpty] = useState(false);

  const performSearch = () => {
    if (!isAuthenticated) {
      // Si el usuario no está autenticado, muestra la ventana emergente de inicio de sesión.
      navigate("/login");
    } else {
      // Realiza la búsqueda con la consulta ingresada
      console.log(`Realizando búsqueda para: ${query}`);
      if (query && query !== "") {
        setIsEmpty(false);
        navigate("/chat-assistant");
      } else {
        setIsEmpty(true);
      }
    }
  };

  // Entrada de voz----------------------------
  const [isListening, setIsListening] = useState(false);

  const handleVoiceInput = () => {
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      recognition.start();
      setIsListening(true);
    }
  };

  const recognition = new window.webkitSpeechRecognition(); // Utiliza la API de reconocimiento de voz del navegador
  recognition.onresult = (event) => {
    const voiceQuery = event.results[0][0].transcript;
    setQuery(voiceQuery);
  };

  return (
    <div className="home">
      {!isAuthenticated && (
        <div className="login-link" onClick={() => navigate("/login")}>
          Log in
        </div>
      )}
      <div className="home-container">
        <img
          className="cognisap-logo-home"
          src={CogniSapLogo}
          alt="CogniSap Logo"
        />
        <p className="logo-text-helper">
          Your <b className="secondary-color">SAP</b> assistant is here, ask
          anything.
        </p>
        <div className="search-section">
          <input
            type="text"
            className="search-bar"
            value={query}
            onChange={handleInputChange}
            onKeyUp={handleKeyUp}
            placeholder="I can help with your SAP questions..."
          />
          <IconButton className="mic-btn" onClick={handleVoiceInput}>
            {isListening ? (
              <BsMic className="end-record" fontSize="large" />
            ) : (
              <BsMic className="start-record" fontSize="large" />
            )}
          </IconButton>

          {/* Search Button */}
          <Button
            className="btn-search"
            variant="contained"
            onClick={performSearch}
          >
            <IoMdSearch />
          </Button>
        </div>
        {isEmpty ? (
          <div className="text-helper">
            <RiErrorWarningFill size={32} /> We noticed you left the query field
            empty. <br></br> Please share your question with us so we can
            provide the best assistance possible.
          </div>
        ) : null}
      </div>

      <Footer />
      {showLoginPopup && (
        <div className="overlay" ref={LoginPopupRef}>
          <Login
            onLogin={handleLogin}
            onClose={() => setShowLoginPopup(false)}
          />
        </div>
      )}

      <div className="banner"> </div>
    </div>
  );
};

export default Home;
