import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import "./Carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// utils
import Webservice from "../../utils/Webservice";

// components
import Card from "../Card/Card";

export default function CarouselCards() {
  const [consultantsData, setConsultantsData] = useState(null);

  useEffect(() => {
    const fetchDataConsultants = async () => {
      Webservice.get("consultants").then((result) => {
        console.log(result);
        setConsultantsData(result.consultants);
      });
    };

    fetchDataConsultants();
  }, []);


  return (
    <div className="swiper-container">
      {consultantsData ? (
        consultantsData.map((consultant, index) => (
          <div key={index}>
            <Card consultant={consultant} index={index} />
          </div>
        ))
      ) : (
        <div className="loading-page">
          <ColorRing
            visible={true}
            height="40"
            width="40"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#379AE6FF",
              "#379AE6FF",
              "#379AE6FF",
              "#379AE6FF",
              "#379AE6FF",
            ]}
          />
        </div>
      )}
    </div>
  );
}
