import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";

const MarkdownTypewriter = ({ text, speed = 20, onTextChange }) => {
  const [part, setPart] = useState("");
  const [index, setIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const typeNextCharacter = () => {
      if (index < text.length && isTyping) {
        const textChar = text.charAt(index);

        if (textChar === "\n") {
          onTextChange();
        }

        if (isMounted) {
          setPart((prev) => prev + textChar);
          setIndex((prev) => prev + 1);
        }
      }
    };

    const intervalId = setInterval(typeNextCharacter, speed);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [index, text, speed, onTextChange, isTyping]);

  useEffect(() => {
    setPart("");
    setIndex(0);
  }, [text]);

  useEffect(() => {
    setIsTyping(false);

    setPart("");
    setIndex(0);

    const timeoutId = setTimeout(() => {
      setIsTyping(true);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [text, setIsTyping]);

  return <Markdown>{part}</Markdown>;
};

export default MarkdownTypewriter;
