import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// context
import { AuthContext } from "../../context/AuthContext";

// styles
import "./ChooseAvatar.css";

// material ui
import Button from "@mui/material/Button";

// avatars
import avatar1 from "../../assets/images/avatars/1.png";
import avatar2 from "../../assets/images/avatars/2.png";
import avatar3 from "../../assets/images/avatars/3.png";
import avatar4 from "../../assets/images/avatars/4.png";
import avatar5 from "../../assets/images/avatars/5.png";
import avatar6 from "../../assets/images/avatars/6.png";
import avatar7 from "../../assets/images/avatars/7.png";
import avatar8 from "../../assets/images/avatars/8.png";
import avatar9 from "../../assets/images/avatars/9.png";
import avatar10 from "../../assets/images/avatars/10.png";
import avatar11 from "../../assets/images/avatars/11.png";
import avatar12 from "../../assets/images/avatars/12.png";
import avatar13 from "../../assets/images/avatars/13.png";
import avatar14 from "../../assets/images/avatars/14.png";
import avatar15 from "../../assets/images/avatars/15.png";
import avatar16 from "../../assets/images/avatars/16.png";

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
];

export default function ChooseAvatar() {
  const navigate = useNavigate();

  // context
  const { userData, setUserData, isAuthenticated } = useContext(AuthContext);

  // state
  const [selectedAvatar, setSelectedAvatar] = useState(avatar1);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#00bdd6");

  useEffect(() => {
    if (isAuthenticated && userData) {
      if (userData && userData.avatar) {
        setSelectedAvatarIndex(userData.avatar);
        setSelectedAvatar(avatars[userData.avatar]);
        setSelectedColor(userData.color);
      }
    }
  }, [userData, isAuthenticated]);

  const handleAvatarChange = (avatar, index) => {
    setSelectedAvatar(avatar);
    setSelectedAvatarIndex(index);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleSave = () => {
    setUserData({
      ...userData,
      avatar: selectedAvatarIndex,
      color: selectedColor,
    });

    setTimeout(() => {
      if (userData.rol === "CONSULTANT") {
        navigate("/consultant-profile");
      }

      if (userData.rol === "COMPANY") {
        navigate("/company-profile");
      }
    }, 1000);
  };

  return (
    <div className="choose-avatar">
      <div className="choose-avatar-container">
        <div className="edit-avatar">
          <p className="title-edit-avatar">Choose an avatar</p>
          <p className="text-edit-avatar">Select one of the options below</p>
          <div className="avatar-edit">
            <img
              src={selectedAvatar}
              className="avatar avatar-selected"
              alt=""
            />
            <div
              className="circle-color"
              style={{ backgroundColor: selectedColor }}
            ></div>
          </div>
          <div className="edit-color">
            <div
              className="color1 colors-edit"
              onClick={() => handleColorChange("#00bdd6")}
            ></div>
            <div
              className="color2 colors-edit"
              onClick={() => handleColorChange("#8353e2")}
            ></div>
            <div
              className="color3 colors-edit"
              onClick={() => handleColorChange("#4069e5")}
            ></div>
            <div
              className="color4 colors-edit"
              onClick={() => handleColorChange("#ed7d2d")}
            ></div>
            <div
              className="color5 colors-edit"
              onClick={() => handleColorChange("#e05858")}
            ></div>
            <div
              className="color6 colors-edit"
              onClick={() => handleColorChange("#1d2128")}
            ></div>
            <div
              className="color7 colors-edit"
              onClick={() => handleColorChange("#dee1e6")}
            ></div>
          </div>

          <div className="avatars-btn-container">
            <Button
              className="avatars-save-btn btn-primary"
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="avatars">
          {avatars.map((avatar, index) => (
            <img
              key={index}
              className="avatar"
              src={avatar}
              alt={`Avatar ${index + 1}`}
              onClick={() => handleAvatarChange(avatar, index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
