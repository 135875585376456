import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// styles
import "./ModalNewQuestion.css";

// icons
import { IoMdClose } from "react-icons/io";
// import { LuUploadCloud } from "react-icons/lu";
import { MdOutlineTipsAndUpdates } from "react-icons/md";

// material ui
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

function ModalNewQuestion({ open, setOpen }) {
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  // const [selectedFiles, setSelectedFiles] = useState([]);

  // const handleFileChange = (event) => {
  //   const filesArray = Array.from(event.target.files);
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
  // };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = Array.from(event.dataTransfer.files);
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  // };

  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };

  // const handleUpload = () => {
  //   if (selectedFiles.length > 0) {
  //     console.log("Subiendo archivos:", selectedFiles);
  //   } else {
  //     console.log("No se han seleccionado archivos.");
  //   }
  // };

  // console.log(handleUpload);

  const [formData, setFormData] = useState({
    question: "",
    description: "",
    attachments: [],
    topics: ["BTP", "FI"],
    rate: 4.5,
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchData = async () => {
    try {
      const apiUrl = "/api/questions";
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Respuesta exitosa:", data);

      if (data.rowKey) {
        navigate("./" + data.rowKey);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    console.log("Formulario enviado:", formData);

    await fetchData();

    handleClose();

    setFormData({
      question: "",
      description: "",
      attachments: [],
      topics: ["BTP", "FI"],
      rate: 4.5,
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className="question-modal-container">
            <div className="header-question-modal">
              <Typography variant="h4" className="title-question-modal">
                Post your question
              </Typography>
              <div className="close-question-modal" onClick={handleClose}>
                <IoMdClose className="close-modal-btn" />
              </div>
            </div>
            <form onSubmit={handleFormSubmit} className="form-new-question">
              <div className="message-question-modal">
                <p>
                  <MdOutlineTipsAndUpdates size={24} /> Tips for getting good
                  responses right away
                </p>
                <ul>
                  <li>Make sure your question hasn't been asked before</li>
                  <li>Ensure that your question is concise and on-topic</li>
                  <li>Check for any spelling or grammatical errors</li>
                </ul>
              </div>
              <div className="title-question">
                <p className="subtitles-question-form">Title</p>
                <div className="title-question-input">
                  <input
                    type="text"
                    className="question-input"
                    name="question"
                    required={true}
                    value={formData.question}
                    onChange={handleFormChange}
                    placeholder="Enter a title for your question..."
                  />
                </div>
              </div>
              <div className="description-question">
                <p className="subtitles-question-form">Description</p>
                <textarea
                  className="description-question-input"
                  name="description"
                  required={true}
                  value={formData.description}
                  onChange={handleFormChange}
                  placeholder='Start the question with "What", "How", "Why", etc.'
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              {/* <div className="attachments-question">
                <p className="subtitles-question-form">
                  Attachments (max 3 files of 10MB at most):
                </p>
                <div className="attachments-question-files">
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              </div> */}
              {/* <div
                className="upload-question-files"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <LuUploadCloud className="upload-question-icon" />
                <p className="first-upload-question-txt">Drop files here</p>
                <p className="second-upload-question-txt">OR</p>
                <label for="file-question-input" className="upload-file-question-btn">
                  Browse files
                </label>
                <input
                  id="file-question-input"
                  className="file-question-input"
                  type="file"
                  onChange={handleFileChange}
                />
              </div> */}
              <div className="topics-action-question-btns">
                {/* <div className="detected-question-txt">
                  <p>Detected Topics: </p>
                  <Button
                    className="topic-question-btn"
                    size="small"
                    variant="outlined"
                  >
                    BTP
                  </Button>
                  <Button
                    className="topic-question-btn"
                    size="small"
                    variant="outlined"
                  >
                    ABAP
                  </Button>
                  <Button
                    className="topic-question-btn"
                    size="small"
                    variant="outlined"
                  >
                    FI
                  </Button>
                </div> */}
                <div className="action-question-btns">
                  <Button
                    type="button"
                    className="cancel-question-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="create-question-btn">
                    Create
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalNewQuestion;
