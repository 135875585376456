import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

// styles
import "./QuestionComment.css";

// components
import AvatarAser from "../Avatar/Avatar";

// icons
import { TbArrowBigUp, TbArrowBigDown } from "react-icons/tb";

export default function QuestionComment({ reply }) {
  console.log(reply);
  // const navigate = useNavigate();

  const [more, setMore] = useState(false);

  const handleMore = () => {
    const toogle = !more;
    setMore(toogle);
  };

  // const handleOpenQuestion = (reply) => {
  //   navigate("./" + reply.rowKey);
  // };

  const formatTimeDifference = (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();

    const timeDifferenceInMilliseconds = currentDate - creationDate;
    const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
    const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
    const timeDifferenceInHours = timeDifferenceInMinutes / 60;
    const timeDifferenceInDays = timeDifferenceInHours / 24;
    const timeDifferenceInMonths = timeDifferenceInDays / 30;

    if (timeDifferenceInSeconds < 60) {
      return `${Math.round(timeDifferenceInSeconds)} seconds ago`;
    } else if (timeDifferenceInMinutes < 60) {
      return `${Math.round(timeDifferenceInMinutes)} minutes ago`;
    } else if (timeDifferenceInHours < 24) {
      return `${Math.round(timeDifferenceInHours)} hours ago`;
    } else if (timeDifferenceInDays < 30) {
      return `${Math.round(timeDifferenceInDays)} days ago`;
    } else if (timeDifferenceInMonths < 12) {
      return `${Math.round(timeDifferenceInMonths)} months ago`;
    } else {
      const day = creationDate.getDate();
      const month = creationDate.getMonth() + 1;
      const year = creationDate.getFullYear();

      return `${day}/${month}/${year}`;
    }
  };

  return (
    <div className="question-replies-container">
      <div className="question-user-reply">
        <div className="avatar-reply">
          <AvatarAser size={36} />
        </div>
        <div className="user-data-reply">
          <p className="name-user-reply">{reply.userName}</p>
          <p className="about-user-reply">
            Consultant - Updated {formatTimeDifference(reply.timestamp)}
          </p>
        </div>
      </div>
      <div className="question-reply">
        <div className="description-reply">
          <p
            className={
              "description-replies-txt " +
              (more ? "description-replies-txt-open" : "")
            }
          >
            {reply.text}
          </p>
          <div
            className="more-reply"
            onClick={() => {
              handleMore();
            }}
          >
            (More)
          </div>
        </div>
      </div>
      <div className="valoration-question-reply">
        <div className="positive-replies-btn">
          <TbArrowBigUp className="positive-replies-icon" size={25} />
          <p>Vote Positive - {reply.rate} </p>
        </div>
        <div className="negative-replies-btn">
          <TbArrowBigDown className="negative-replies-icon" size={25} />
        </div>
      </div>
    </div>
  );
}
