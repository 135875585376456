import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// styles
import "./ConsultantProfile.css";

// context
import { AuthContext } from "../../context/AuthContext";

// material ui
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";

// icons
import { FaRegFolder } from "react-icons/fa6";

// utils
import Webservice from "../../utils/Webservice";

// components
//import SAPModulesForm from "../../components/SAPModulesForm/SAPModulesForm";
import SAPModulesFormVersions from "../../components/SAPModulesFormVersions/SAPModulesFormVersions";
import SAPModulesFormAreas from "../../components/SAPModulesFormAreas/SAPModulesFormAreas";
import Avatar from "../../components/Avatar/Avatar";

function ConsultantProfile() {
  const navigate = useNavigate();

  // context
  const { isAuthenticated, userData, reloadUser } = useContext(AuthContext);

  // state
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    years: "",
    modules: [],
    areas: [],
  });
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    email: "",
    years: "",
  });

  useEffect(() => {
    if (isAuthenticated && userData) {
      if (userData && !userData.avatar) {
        navigate("/avatars");
      }
    }
  }, [userData, isAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated && userData.profile) {
      // modules
      if (typeof userData.profile.modules == "string") {
        userData.profile.modules = userData.profile.modules.split(",");
      }
      setSelectedModules(userData.profile.modules || []);

      // versions
      if (typeof userData.profile.versions == "string") {
        userData.profile.versions = userData.profile.versions.split(",");
      }
      setSelectedVersions(userData.profile.versions || []);

      // areas
      if (typeof userData.profile.areas == "string") {
        userData.profile.areas = userData.profile.areas.split(",");
      }
      setSelectedAreas(userData.profile.areas || []);

      setFormData((prevFormData) => ({
        ...prevFormData,
        ...userData.profile,
      }));
    }
  }, [isAuthenticated, userData, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      modules: selectedModules,
    }));
  }, [selectedModules, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      areas: selectedAreas,
    }));
  }, [selectedAreas, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      versions: selectedVersions,
    }));
  }, [selectedVersions, setFormData]);

  // methods
  // const handleModulesSelected = (modules) => {
  //   setSelectedModules(modules);
  // };

  const handleModulesVersions = (versions) => {
    setSelectedVersions(versions);
  };

  const handleAreasSelected = (areas) => {
    setSelectedAreas(areas);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectAvatar = () => {
    navigate("/avatars");
  };

  const handleSave = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Required field";
    }

    if (!formData.phone) {
      errors.phone = "Required field";
    }

    if (!formData.email) {
      errors.email = "Required field";
    }

    if (!formData.years) {
      errors.years = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      setErrorMessages({});
      formData.modules = formData.modules.join(",");
      formData.areas = formData.areas.join(",");
      formData.versions = formData.versions.join(",");
      console.log(formData);
      const saveData = async () => {
        await Webservice.post("consultants", {
          route: "setProfile",
          ...formData,
        });

        reloadUser();
      };

      saveData();
    } else {
      setErrorMessages(errors);
    }
  };

  return (
    <div className="consultant-profile">
      <div className="consultant-profile-container">
        <div className="profile-consultant">
          <p className="titles-consultant-profile">My profile</p>
          <div className="profile-consultant-data">
            <Avatar size={128} />

            <div className="name-btn-consultant-profile">
              <div className="name-consultant">
                <p className="name-consultant-profile">Anna Jones</p>
                <p className="title-consultant-profile">Professional title</p>
              </div>

              <Button className="btn-primary" onClick={handleSelectAvatar}>
                Select Avatar
              </Button>
            </div>
            <div className="consultant-rating-projects">
              <div className="consultant-rating-projects-container">
                <p className="consultant-rating-projects-txt">RATING</p>
                <p className="consultant-rating-projects-num">4.5</p>
                <Rating
                  name="read-only"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  size="small"
                />
              </div>
              <div className="consultant-rating-projects-container">
                <p className="consultant-rating-projects-txt">PROJECTS</p>
                <p className="consultant-rating-projects-num">50</p>
                <FaRegFolder className="consultant-folder-project-icon" />
              </div>
            </div>
          </div>

          <form className="form-consultant-profile">
            <div className="about-consultant-profile">
              <p className="about-consultant-title">About</p>
              <p className="about-consultant-txt">Tell us about yourself</p>
            </div>
            <div className="consultant-profile-form-field">
              <label className="consultant-profile-title-inputs">
                Name
                <input
                  type="text"
                  name="name"
                  className="consultant-profile-inputs-form"
                  placeholder="James Harrid"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              {errorMessages.name && (
                <span className="consultant-profile-required-message">
                  {errorMessages.name}
                </span>
              )}
            </div>

            <div className="consultant-profile-form-field">
              <label className="consultant-profile-title-inputs">
                Phone
                <input
                  type="number"
                  name="phone"
                  className="consultant-profile-inputs-form"
                  placeholder="123-456-7890"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </label>
              {errorMessages.phone && (
                <span className="consultant-profile-required-message">
                  {errorMessages.phone}
                </span>
              )}
            </div>

            <div className="consultant-profile-form-field">
              <label className="consultant-profile-title-inputs">
                Email
                <input
                  type="email"
                  name="email"
                  className="consultant-profile-inputs-form"
                  placeholder="example@email.com"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              {errorMessages.email && (
                <span className="consultant-profile-required-message">
                  {errorMessages.email}
                </span>
              )}
            </div>

            <div className="consultant-profile-form-field">
              <label className="consultant-profile-title-inputs">
                Years of professional experience
                <select
                  name="years"
                  className="consultant-profile-selects-form"
                  value={formData.years}
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="+10">+10</option>
                </select>
              </label>
              {errorMessages.years && (
                <span className="consultant-profile-required-message">
                  {errorMessages.years}
                </span>
              )}
            </div>

            <div className="consultant-profile-btn-container">
              <Button className="btn-primary" onClick={handleSave}>
                Save changes
              </Button>
            </div>
          </form>
        </div>
        <div className="consultant-modules">
          {/* <p className="titles-consultant-profile">SAP modules</p> */}
          <p className="consultant-profile-mudules-txt">
            Which SAP modules are you experienced in?
          </p>
          {/* <div className="consultant-profile-modules">
            <SAPModulesForm
              onModulesSelected={handleModulesSelected}
              selectedModules={selectedModules}
            />
          </div> */}
          <div className="consultant-profile-versions">
            <SAPModulesFormVersions
              onModulesSelected={handleModulesVersions}
              selectedModules={selectedVersions}
            />
          </div>{" "}
          <div className="consultant-profile-versions">
            <SAPModulesFormAreas
              onModulesSelected={handleAreasSelected}
              selectedModules={selectedAreas}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConsultantProfile;
