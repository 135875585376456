import React, { useState, useContext, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { ColorRing, ThreeDots } from "react-loader-spinner";
import ReactMarkdown from "react-markdown";

import "./Chat.css";

// context
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "../../context/QueryContext";

// icons
import { IoMdSearch } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { AiOutlineDislike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BsChatSquare } from "react-icons/bs";
import { FaRegCircleStop } from "react-icons/fa6";

// material ui
import { Button } from "@mui/material";
import { IconButton } from "@mui/material";

// components
import Footer from "../../components/Footer/Footer";
import ModalFormCompany from "../../components/ModalFormCompany/ModalFormCompany";
import ModalFormConsultant from "../../components/ModalFormConsultant/ModalFormConsultant";
import ModalUserType from "../../components/ModalUserType/ModalUserType";

let ws;
function Chat() {
  const { query, setQuery } = useQuery();

  // state
  const [message, setMessage] = useState("");
  const [Like, setLike] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const [stopChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFormCompanyOpen, setIsModalFormCompanyOpen] = useState(false);
  const [isModalFormAgentOpen, setIsModalFormAgentOpen] = useState(false);
  const [Dislike, setDislike] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);

  // refs
  const textAreaRef = useRef(null);
  const chatContainerRef = useRef(null);
  const bottomRef = useRef(null);

  // context
  const { isAuthenticated, setIsAuthenticated, userData } =
    useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      setIsModalOpen(userData && !userData.rol);
    }
  }, [userData]);

  useEffect(() => {
    // Agrega la búsqueda como un mensaje cuando se monta el componente
    const fetchAndSendMessage = async () => {
      try {
        if (query && query !== "") {
          localStorage.removeItem("searchQuery");
          setQuery("");

          // Si el POST es exitoso, agrega el mensaje de búsqueda a los mensajes
          const searchMessage = {
            text: query,
            type: "sent",
            timestamp: Date.now(),
          };
          setMessages((prevMessages) => [...prevMessages, searchMessage]);

          // Realiza el POST al servidor con el mensaje de búsqueda
          const response = await fetch("/api/chat", {
            method: "POST",
            body: JSON.stringify({
              chatId: window.location.search.split("=")[1],
              message: query,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            console.error("Error al enviar la búsqueda");
            return;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAndSendMessage();
  }, [query, setQuery]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageCount]);

  useEffect(() => {
    let timer;
    if (messageCount === 1 || (messageCount === 2 && messages.length === 1)) {
      // Temporizador más largo para messageCount igual a 1
      timer = setInterval(() => {
        setMessageCount(0); // Reiniciar el contador después de comprobar los mensajes
      }, 7000); // Reinicia el contador cada x segundos si hay un solo mensaje
    } else if (messageCount >= 2) {
      // Temporizador más corto para messageCount mayor que 1
      timer = setInterval(() => {
        setMessageCount(0); // Reiniciar el contador después de comprobar los mensajes
      }, 3000); // Reinicia el contador cada 3 segundos si hay más de un mensaje
    }

    return () => {
      clearInterval(timer);
    };
  }, [messageCount, messages]);

  // Efecto para actualizar el contador de mensajes cada vez que se agregue un nuevo mensaje
  useEffect(() => {
    updateMessageCount();
  }, [messages]);

  useEffect(() => {
    async function connectChat() {
      let res = await fetch(`/api/negotiate`);
      let url = await res.json();
      ws = new WebSocket(url.url);
      ws.onopen = () => console.log("connected");
      ws.onmessage = (e) => {
        //if (!stopChat) {
        const messageData = JSON.parse(e.data);
        const newMessage = {
          text: messageData.message,
          type: "received",
          timestamp: Date.now(),
        };

        setMessages((prevMessages) => {
          const uniqueMessagesSet = new Set(
            prevMessages.map((msg) => msg.text)
          );
          if (!uniqueMessagesSet.has(newMessage.text)) {
            return [...prevMessages, newMessage];
          }
          return prevMessages;
        });
      };
      ws.onclose = (e) => console.log("disconnected", e);
    }

    const fetchData = async () => {
      try {
        setLoading(true);

        // Coloca tu lógica de autenticación aquí, por ejemplo, un fetch
        const response = await fetch("/.auth/me");
        const data = await response.json();

        if (data.clientPrincipal) {
          setIsAuthenticated(true);
          await connectChat();
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.error("Error de autenticación: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setIsAuthenticated, stopChat]);

  const handleDislikeBtn = () => {
    if (Dislike) {
      setDislike(false);
      setLike(false); // Establecer el botón de "Like" a falso cuando se da "Dislike"
    } else {
      setDislike(true);
      setLike(false); // Asegurarse de que el botón de "Like" esté en falso cuando se da "Dislike"
    }
  };

  // Función para actualizar el contador de mensajes cuando se agrega un nuevo mensaje
  const updateMessageCount = () => {
    setMessageCount((prevCount) => prevCount + 1);
  };

  if (loading) {
    // Mientras se está realizando el proceso de autenticación, muestra un indicador de carga
    return (
      <div className="LoadingPage">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={[
            "#379AE6FF",
            "#379AE6FF",
            "#379AE6FF",
            "#379AE6FF",
            "#379AE6FF",
          ]}
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    // Si no está autenticado, redirige a la página de login
    return <Navigate to="/login" />;
  }

  const handleLikeBtn = () => {
    if (Like) {
      setLike(false);
      setDislike(false); // Establecer el botón de "Dislike" a falso cuando se da "Like"
    } else {
      setLike(true);
      setDislike(false); // Asegurarse de que el botón de "Dislike" esté en falso cuando se da "Like"
    }
  };

  const performSearch = async () => {
    let count = 0 + searchCount;
    setSearchCount(++count);

    if (searchCount > 1) {
      const userType = localStorage.getItem("userType");
      if (userType === "COMPANY") {
        setIsModalFormCompanyOpen(true);
      }

      if (userType === "CONSULTANT") {
        setIsModalFormAgentOpen(true);
      }
    }

    const sentMessage = {
      text: message,
      type: "sent",
      timestamp: Date.now(),
      sentBy: "You",
    };
    setMessages((prevMessages) => [...prevMessages, sentMessage]);
    setMessage(""); // Limpia el contenido del textarea después de enviar el mensaje

    try {
      const response = await fetch("/api/chat", {
        method: "POST",
        body: JSON.stringify({
          chatId: window.location.search.split("=")[1],
          message: message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error("Error al enviar el mensaje");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const enterPressed = async (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault(); // Evita el comportamiento por defecto (salto de línea)
      await performSearch();
    }
  };

  const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const handleInput = (e) => {
    e.preventDefault();
    setMessage(capitalize(e.target.value));
  };

  return (
    <div className="chat">
      {isModalFormCompanyOpen && <ModalFormCompany />}
      {isModalFormAgentOpen && <ModalFormConsultant />}
      {isModalOpen && <ModalUserType />}

      <div className="chat-container">
        <div className="chat-inside-container">
          <div className="section-btns">
            <IconButton onClick={handleLikeBtn}>
              {Like ? (
                <FaHeart className="like-btn" fontSize="18px" />
              ) : (
                <FaRegHeart className="like-btn" fontSize="18px" />
              )}
            </IconButton>
            <IconButton onClick={handleDislikeBtn}>
              {Dislike ? (
                <AiFillDislike className="like-btn" fontSize="20px" />
              ) : (
                <AiOutlineDislike className="like-btn" fontSize="20px" />
              )}
            </IconButton>
          </div>
          <div className="chat-text">
            {messages
              .sort((a, b) => a.timestamp - b.timestamp)
              .reduce((acc, msg, index, array) => {
                if (msg.type === "received") {
                  const lastMsg = array[index - 1];
                  if (lastMsg && lastMsg.type === "received") {
                    // Concatena mensajes recibidos consecutivos
                    acc[acc.length - 1] += `${msg.text}`;
                  } else {
                    acc.push(msg.text);
                  }
                } else {
                  acc.push(msg);
                }
                return acc;
              }, [])
              .map((msg, index) => (
                <div
                  key={index}
                  className={
                    typeof msg === "string"
                      ? "incoming-message"
                      : "outgoing-message"
                  }
                >
                  <p
                    className={`message ${
                      typeof msg === "string"
                        ? "received-message"
                        : "sent-message"
                    }`}
                  >
                    {typeof msg === "string" ? (
                      <ReactMarkdown>{msg}</ReactMarkdown>
                    ) : (
                      <ReactMarkdown>{msg.text}</ReactMarkdown>
                    )}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {messageCount !== 0 && (
          <div className="waiting-animation">
            <ThreeDots
              visible={true}
              height="30"
              width="30"
              color="#9095a0"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
        <div ref={bottomRef}></div>
      </div>
      <div className="ask-expert">
        <p>Not satisfied?</p>
        <Button
          className="expert-btn btn-secondary"
          variant="contained"
          onClick={() => {
            window.location.href = "/consultants";
          }}
          startIcon={<AiOutlineUser />}
          disabled={messageCount !== 0}
        >
          Ask to an expert
        </Button>
      </div>
      <div className="input-chat-suggestions">
        <div className="input-chat" ref={chatContainerRef}>
          <BsChatSquare size={20} />
          <textarea
            ref={textAreaRef}
            className="bar-chat"
            placeholder="Add more context to your questions..."
            value={message}
            onChange={handleInput}
            disabled={messageCount !== 0}
            onKeyUp={enterPressed}
            autoComplete="false"
            rows={1}
          />

          {messageCount !== 0 ? (
            <Button className="stop-btn btn-search">
              <FaRegCircleStop size={20} />
            </Button>
          ) : (
            <Button variant="contained" className="search-btn btn-search" onClick={performSearch}>
              <IoMdSearch />
            </Button>
          )}
        </div>
        <div className="suggestions">
          <p>Suggestions</p>
          <Button className="suggestions-btn" size="small" variant="outlined">
            What is OData?
          </Button>
          <Button className="suggestions-btn" size="small" variant="outlined">
            What is SOAP?
          </Button>
          <Button className="suggestions-btn" size="small" variant="outlined">
            What means RESTful?
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Chat;
