import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// styles
import "./CompanyProfile.css";

// context
import { AuthContext } from "../../context/AuthContext";

// material ui
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";

// icons
import { FaRegFolder } from "react-icons/fa6";

// utils
import Webservice from "../../utils/Webservice";

// components
//import SAPModulesForm from "../../components/SAPModulesForm/SAPModulesForm";
import Avatar from "../../components/Avatar/Avatar";
import SAPModulesFormVersions from "../../components/SAPModulesFormVersions/SAPModulesFormVersions";
import SAPModulesFormAreas from "../../components/SAPModulesFormAreas/SAPModulesFormAreas";

const sapIndustries = [
  "Automotive",
  "Chemicals",
  "Construction and Real Estate",
  "Consumer Products",
  "Financial Services",
  "High Tech and Electronics",
  "Manufacturing",
  "Oil and Gas",
  "Pharmaceuticals and Healthcare",
  "Public Sector",
  "Retail",
  "Telecommunications",
  "Transportation and Logistics",
  "Utilities",
];

function CompanyProfile() {
  const navigate = useNavigate();

  // context
  const { isAuthenticated, userData, reloadUser } = useContext(AuthContext);

  // state
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    sector: "",
    modules: [],
    areas: [],
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    email: "",
    sector: "",
  });

  useEffect(() => {
    if (isAuthenticated && userData) {
      if (userData && !userData.avatar) {
        navigate("/avatars");
      }
    }
  }, [userData, isAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated && userData.profile) {
      // modules
      if (typeof userData.profile.modules == "string") {
        userData.profile.modules = userData.profile.modules.split(",");
      }
      setSelectedModules(userData.profile.modules || []);

      // versions
      if (typeof userData.profile.versions == "string") {
        userData.profile.versions = userData.profile.versions.split(",");
      }
      setSelectedVersions(userData.profile.versions || []);

      // areas
      if (typeof userData.profile.areas == "string") {
        userData.profile.areas = userData.profile.areas.split(",");
      }
      setSelectedAreas(userData.profile.areas || []);

      setFormData((prevFormData) => ({
        ...prevFormData,
        ...userData.profile,
      }));
    }
  }, [isAuthenticated, userData, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      modules: selectedModules,
    }));
  }, [selectedModules, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      areas: selectedAreas,
    }));
  }, [selectedAreas, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      versions: selectedVersions,
    }));
  }, [selectedVersions, setFormData]);

  // methods
  // const handleModulesSelected = (modules) => {
  //   setSelectedModules(modules);
  // };

  const handleAreasSelected = (areas) => {
    setSelectedAreas(areas);
  };

  const handleModulesVersions = (versions) => {
    setSelectedVersions(versions);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectAvatar = () => {
    navigate("/avatars");
  };

  const handleSave = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Required field";
    }

    if (!formData.phone) {
      errors.phone = "Required field";
    }

    if (!formData.email) {
      errors.email = "Required field";
    }

    if (!formData.sector) {
      errors.sector = "Required field";
    }

    if (Object.keys(errors).length === 0) {
      setErrorMessages({});

      formData.modules = formData.modules.join(",");
      formData.areas = formData.areas.join(",");
      formData.versions = formData.versions.join(",");
      console.log(formData);
      const saveData = async () => {
        await Webservice.post("company", {
          route: "setProfile",
          ...formData,
        });

        reloadUser();
      };

      saveData();
    } else {
      setErrorMessages(errors);
    }
  };

  return (
    <div className="company-profile">
      <div className="company-profile-container">
        <div className="profile">
          <p className="titles-company-profile">My profile</p>
          <div className="profile-data">
            <Avatar size={128} />
            <div className="name-btn-profile">
              <div className="name-company">
                <p className="name-profile">Anna Jones</p>
                <p className="title-profile">Professional title</p>
              </div>
              <Button className="btn-primary" onClick={handleSelectAvatar}>
                Select Avatar
              </Button>
            </div>
            <div className="rating-projects">
              <div className="rating-projects-container">
                <p className="rating-projects-txt">RATING</p>
                <p className="rating-projects-num">4.5</p>
                <Rating
                  name="read-only"
                  value={4.5}
                  precision={0.5}
                  readOnly
                  size="small"
                />
              </div>
              <div className="rating-projects-container">
                <p className="rating-projects-txt">PROJECTS</p>
                <p className="rating-projects-num">50</p>
                <FaRegFolder className="folder-project-icon" />
              </div>
            </div>
          </div>

          <form className="form-company-profile">
            <div className="about-profile">
              <p className="about-title">About</p>
              <p className="about-txt">Tell us about yourself</p>
            </div>
            <div className="company-profile-form-field">
              <label className="company-profile-title-inputs">
                Company Name
                <input
                  type="text"
                  name="name"
                  className="company-profile-inputs-form"
                  placeholder="James Harrid"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              {errorMessages.name && (
                <span className="company-profile-required-message">
                  {errorMessages.name}
                </span>
              )}
            </div>
            <div className="company-profile-form-field">
              <label className="company-profile-title-inputs">
                Phone
                <input
                  type="number"
                  name="phone"
                  className="company-profile-inputs-form"
                  placeholder="123-456-7890"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </label>
              {errorMessages.phone && (
                <span className="company-profile-required-message">
                  {errorMessages.phone}
                </span>
              )}
            </div>
            <div className="company-profile-form-field">
              <label className="company-profile-title-inputs">
                Email
                <input
                  type="email"
                  name="email"
                  className="company-profile-inputs-form"
                  placeholder="example@email.com"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              {errorMessages.email && (
                <span className="company-profile-required-message">
                  {errorMessages.email}
                </span>
              )}
            </div>
            <div className="company-profile-form-field">
              <label className="company-profile-title-inputs">
                Industry sector
                <select
                  name="sector"
                  className="company-profile-selects-form"
                  value={formData.sector}
                  onChange={handleInputChange}
                  required
                >
                  <option value=""></option>
                  {sapIndustries.map((sector, i) => (
                    <option value={sector} key={"sector" + i}>
                      {sector}
                    </option>
                  ))}
                </select>
              </label>
              {errorMessages.sector && (
                <span className="company-profile-required-message">
                  {errorMessages.sector}
                </span>
              )}
            </div>

            <div className="company-profile-container-btn">
              <Button className="btn-primary" onClick={handleSave}>
                Save changes
              </Button>
            </div>
          </form>
        </div>
        <div className="modules-company">
          {/* <p className="titles-company-profile">SAP modules</p> */}
          <p className="modules-company-txt">
            Which SAP modules are you experienced in?
          </p>
          {/* <div className="modules">
            <SAPModulesForm
              onModulesSelected={handleModulesSelected}
              selectedModules={selectedModules}
            />
          </div> */}
          <div className="modules">
            <SAPModulesFormVersions
              onModulesSelected={handleModulesVersions}
              selectedModules={selectedVersions}
            />
          </div>{" "}
          <div className="modules">
            <SAPModulesFormAreas
              onModulesSelected={handleAreasSelected}
              selectedModules={selectedAreas}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
