import React, { useState, useEffect, useRef } from "react";
import "./SearchMessage.css";

const messages = [
  "🔍 Expanding our search to bring you the best results... Thanks for your patience!",
  "🌐 Exploring hidden corners of the internet to find what you need.",
  "⏳ Filtering content to present you with only the most relevant information.",
  "✨ Organizing data collected from specialized forums. Almost ready!",
  "🤖 Our algorithms are working hard to customize your search.",
  "📚 Consulting our most trustworthy sources. Quality comes first.",
  "🔄 Updating results with the latest information available.",
  "🔬 Analyzing details to ensure accurate and useful responses.",
  "💡 Integrating unique insights from various online communities.",
  "🎯 About to finish. Preparing to deliver the best of our search to you.",
];
var isLoading = false;

export default function SearchMessage({ onMessageChange, loading }) {
  const [message, setMessage] = useState("");
  const messageRef = useRef(null);

  useEffect(() => {
    isLoading = loading;

    const handleRandom = () => {
      console.log("handleRandom-loading", isLoading);
      if (isLoading) {
        const mensajeContainer = messageRef.current;
        mensajeContainer?.classList.add("hide-up");
        const messageData =
          messages[Math.floor(Math.random() * messages.length)];

        setTimeout(() => {
          mensajeContainer?.classList.remove("hide-up");
          mensajeContainer?.classList.add("show");
          setMessage(messageData);
          onMessageChange(messageData);
          setTimeout(() => {
            mensajeContainer?.classList.remove("show");
            setTimeout(() => {
              handleRandom();
            }, 4000);
          }, 500);
        }, 500);
      }
    };
    if (isLoading) {
      handleRandom();
    }
  }, [loading, onMessageChange]);

  return (
    loading && (
      <div ref={messageRef} className="message-container">
        {message}
      </div>
    )
  );
}
