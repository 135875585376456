import React from "react";
import "./Footer.css";
import Telegram from "../../assets/images/TelegramIcon.png";

function Footer() {
  return (
    <footer className="footer-container">
      <div className="telegram-btn">
        <img className="telegram-icon" src={Telegram} alt="Telegram" />
        <p>We're on Telegram!</p>
      </div>
    </footer>
  );
}

export default Footer;
