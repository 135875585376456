import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// styles
import "./CutSearch.css";

// context
import { useQuery } from "../../context/QueryContext";

// icons
import { IoMdSearch } from "react-icons/io";
import { BsMic } from "react-icons/bs";
import { AiFillQuestionCircle } from "react-icons/ai";


// material ui
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";

// components
import ModalNewQuestion from "../ModalNewQuestion/ModalNewQuestion";

function CutSearch() {
  const location = useLocation(); // Obtiene la ubicación actual usando useLocation
  const { query, setQuery } = useQuery();

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Realiza la búsqueda cuando se presiona "Enter"
      performSearch();
    }
  };
  const performSearch = () => {
    // Realiza la búsqueda con la consulta ingresada
    console.log(`Realizando búsqueda para: ${query}`);
    navigate("/chat-assistant");
  };

  useEffect(() => {
    // Guardar la consulta en localStorage cuando cambie
    localStorage.setItem("searchQuery", query);
  }, [query]);

  useEffect(() => {
    // Obtener el valor almacenado en localStorage
    const storedQuery = localStorage.getItem("searchQuery");

    // Mostrar en la consola
    console.log("Valor almacenado en localStorage:", storedQuery);
  }, []);

  const [isListening, setIsListening] = useState(false);

  const handleVoiceInput = () => {
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      recognition.start();
      setIsListening(true);
    }
  };

  const recognition = new window.webkitSpeechRecognition(); // Utiliza la API de reconocimiento de voz del navegador
  recognition.onresult = (event) => {
    const voiceQuery = event.results[0][0].transcript;
    setQuery(voiceQuery);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  

  return (
    <div className="search-cut-container">
      <div className="search-cut">
        <input
          type="text"
          className="search-input"
          value={query}
          onChange={handleInputChange}
          onKeyUp={handleKeyPress}
          placeholder="I can help with your SAP questions..."
        />
        <IconButton className="mic-btn" onClick={handleVoiceInput}>
          {isListening ? (
            <BsMic className="end-voice" fontSize="large" />
          ) : (
            <BsMic className="start-voice" fontSize="large" />
          )}
        </IconButton>

        {/* Search Button */}
        <Button
          className="btn-search"
          variant="contained"
          onClick={performSearch}
        >
          <IoMdSearch />
        </Button>
      </div>
      {/* </div> */}

      {location.pathname === "/forum" && (
        <Button
          onClick={handleOpen}
          variant="outlined"
          startIcon={<AiFillQuestionCircle size={20} />}
          className="question-btn"
        >
          New question
        </Button>
      )}

      <ModalNewQuestion open={open} setOpen={setOpen}/>

    </div>
  );
}

export default CutSearch;
