import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { AiOutlineGoogle } from "react-icons/ai";
import { SiMicrosoftoutlook } from "react-icons/si";
import { BsFacebook } from "react-icons/bs";
import { AiOutlineApple, AiOutlineWechat } from "react-icons/ai";
import CogniSapLogo from "../../assets/images/cognisap-logo.png";
// import "./LoginRegistration.css";


// Estilo personalizado para los botones
const Btn = styled(Button)({
  fillOpacity: 1,
  border: "none",
  borderRadius: "26px",
  textTransform: "none",
  fontSize: 18,
  padding: "5px 20px",
});

function Registration({ onClose, onLogin }) {
  const handleLoginClick = () => {
    // Realiza el inicio de sesión o cualquier otra lógica necesaria.
    window.location.replace("https://cognisap.com/.auth/login/google");
    onLogin();

    // Cierra el popup llamando a la función onClose
    onClose();
  };

  return (
    <div className="RegistrationContainer">
      
      <div className="LeftSide">
            <h1>Create account</h1>
            <h6>Create an account to use the SAP Assistant</h6>
            <div>
              <div className="TextLeftSide">
                <p>Select your identity provider</p>
              </div>

              <div className="BtnProviders">
                <div>
                  <Btn
                    variant="contained"
                    startIcon={<AiOutlineGoogle />}
                    onClick={handleLoginClick}
                    sx={{
                      color: "#C71610FF",
                      backgroundColor: "#FEF1F1FF",
                      margin: "10px",
                      "&:hover": {
                        color: "#FEF1F1FF",
                        backgroundColor: "#c71610",
                        boxShadow: "none",
                      },
                      "&:focus": {
                        boxShadow: "0 0 0 0.2rem rgba(199, 22, 16, 0.5)",
                      },
                    }}
                  >
                    Google
                  </Btn>
                </div>
                <div>
                  <Btn
                    variant="contained"
                    startIcon={<SiMicrosoftoutlook />}
                    sx={{
                      color: "#379AE6FF",
                      backgroundColor: "#F1F8FDFF",
                      margin: "10px",
                      "&:hover": {
                        color: "#F1F8FDFF",
                        backgroundColor: "#379ae6",
                        boxShadow: "none",
                      },
                      "&:focus": {
                        boxShadow: "0 0 0 0.2rem rgba(55, 154, 230, 0.498)",
                      },
                    }}
                  >
                    Microsoft
                  </Btn>
                </div>
                <div>
                  <Btn
                    variant="contained"
                    startIcon={<BsFacebook />}
                    sx={{
                      color: "#335CA6FF",
                      backgroundColor: "#F3F6FBFF",
                      margin: "10px",
                      "&:hover": {
                        color: "#F3F6FBFF",
                        backgroundColor: "#335ca6",
                        boxShadow: "none",
                      },
                      "&:focus": {
                        boxShadow: "0 0 0 0.2rem rgba(51, 92, 166,.5)",
                      },
                    }}
                  >
                    Facebook
                  </Btn>
                </div>
                <div>
                  <Btn
                    variant="contained"
                    startIcon={<AiOutlineApple />}
                    sx={{
                      color: "#565e6c",
                      backgroundColor: "#F3F4F6FF",
                      margin: "10px",
                      "&:hover": {
                        color: "#F3F4F6FF",
                        backgroundColor: "#565e6c",
                        boxShadow: "none",
                      },
                      "&:focus": {
                        boxShadow: "0 0 0 0.2rem rgba(86, 94, 108,.5)",
                      },
                    }}
                  >
                    Apple
                  </Btn>
                </div>
                <div>
                  <Btn
                    variant="contained"
                    startIcon={<AiOutlineWechat />}
                    sx={{
                      color: "#ED7D2DFF",
                      backgroundColor: "#FEF6F1FF",
                      margin: "10px",
                      "&:hover": {
                        color: "#FEF6F1FF",
                        backgroundColor: "#ED7D2DFF",
                        boxShadow: "none",
                      },
                      "&:focus": {
                        boxShadow: "0 0 0 0.2rem rgba(86, 94, 108,.5)",
                      },
                    }}
                  >
                    WeChat
                  </Btn>
                </div>
              </div>
              <div className="TextLeftSide">
              <button
              type="button"
              onClick={onClose}
              className="custom-link"
            >
              I already have an account
            </button>
              </div>
            </div>
          </div>
          <div className="RightSide">
            <img
              id="CogniSapLogoLogin"
              src={CogniSapLogo}
              alt="CogniSap Logo"
            />
            <p>SAP Assistant: Unlimited queries, instant answers</p>
            <p>Connect with top-notch SAP consultants</p>
            <p>Exciting SAP projects await your expertise</p>
            <p>Join the SAP leading community</p>
          </div>
    </div>
  );
}

export default Registration;
